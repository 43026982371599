import { ShoppingCart } from "@mui/icons-material";
import { Badge, Box, Paper, Typography } from "@mui/material";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

export function StyledAppBar ({ shoppingCart, setShoppingCartOpen }) {

  const location = useLocation();
  const navigate = useNavigate();
  let title;
  const [shoppingCartQuantity, setShoppingCartQuantity] = React.useState(0);
  const [shoppingCartPrice, setShoppingCartPrice] = React.useState(0);

  if(location.pathname === "/") {
    title = "Armicar";
  }

  if(location.pathname === "/comenzi") {
    title = "Comenzi Armicar";
  }

  if(location.pathname === "/produse") {
    title = "Produsele Noastre";
  }

  if(location.pathname === "/finalizare") {
    title = "Comanda"
  }

  if(location.pathname === "/detalii") {
    title = "Detalii"
  }

  function getShoppingCartQuantity() {
    let quantity = 0;

    shoppingCart.forEach((item) => {
      quantity += Number(item.quantity);
    })

    return quantity;
  }

  function getShoppingCartPrice() {
    let price = 0;
    shoppingCart.forEach((item) => {
      price += Number(item.quantity) * Number(item.variant.price);
    })

    return price;
  }

  React.useEffect(() => {
    setShoppingCartQuantity(getShoppingCartQuantity());
    setShoppingCartPrice(getShoppingCartPrice());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shoppingCart])

  return(
    <Paper
      sx={{
        width: "100%",
        minHeight: "40vh",
        backgroundImage: "url(/appbar.jpg)",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
      elevation={20}
    >
      <Box
        sx={{
            background: "rgba(0, 0, 0, 0) url(/pattern-overlay.png) repeat scroll 0 0",
            minHeight: "40vh",
            minWidth: "100%",
            content: '""',
            display: "flex",
            flexDirection: "column"
        }}
      >
        <Box 
          sx={{
            backgroundImage: "url(/main-logo.png)",
            width: "10rem",
            height: "10rem",
            mx: "auto",
            mt: 3,
            mb: -3
          }}
          className="widget"
          onClick={() => { navigate("/"); }}
        />
        <Typography
          sx={{
            fontFamily: "titleFont",
            m: "auto",
            mb: 0,
            fontSize: "4rem",
            color: "primary.contrastText",
            textAlign: "center"
          }}
        >
          { title }
        </Typography>
        <Box
          sx={{
            m: "auto"
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: 2
            }}
            className={shoppingCart.length > 0 ? "cart-button" : ""}
            onClick={() => { setShoppingCartOpen(true); }}
            id="cart-icon"
          >
            <Typography
              color="primary.contrastText"
              variant="h6"
            >
              { shoppingCartPrice } lei
            </Typography>
            <Badge 
              badgeContent={shoppingCartQuantity}
              color="custom"
              sx={{
                color: "primary.contrastText",
              }}
            >
              <ShoppingCart fontSize="large" />
            </Badge>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
            mx: "auto",
            py: 1,
            px: 2,
            backgroundColor: "#e5d96b"
        }}
      >
        <Typography
            sx={{
                color: "primary.contrastText",
                fontWeight: "bold",
                textAlign: "center",
                fontFamily: "oswald",
            }}
            variant="h5"
        >
            Nu doar produse de panificaţie, ci o experienţă de neuitat!
        </Typography>
      </Box>
    </Paper>
  );
}