import { Add, Check, ChevronLeft } from "@mui/icons-material";
import { Box, Button, Divider, Grid, IconButton, List, ListItem, ListItemButton, MenuItem, Paper, TextField, Toolbar, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import produseShop from "../../produse_shop.json"
import { StyledDrawer } from "../../StyledDrawer";
import routes from "../../routes.json"

export default function DetaliiPage({ open, setOpen, toggleDrawer, addToCart }) {

    const navigate = useNavigate();
    const [quantity, setQuantity] = React.useState(1);
    const queryParameters = new URLSearchParams(window.location.search);
    const categoryIndex = queryParameters.get("category");
    const productIndex = queryParameters.get("product");
    const product = produseShop.products[produseShop.categories[categoryIndex].name][productIndex];
    const [selectedVariant, setSelectedVariant] = React.useState(product.variants[0]);

    React.useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return(
        <React.Fragment>
        <StyledDrawer variant="permanent" open={open} id="drawer-main" >
            <Toolbar
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    px: [1],
                }}
            >
            <IconButton onClick={toggleDrawer}>
                <ChevronLeft color="custom" />
            </IconButton>
            </Toolbar>
            <Divider color="#e5d96b" sx={{width: "90%", mx: "auto"}} />
            <List>
                {
                    routes.drawer.map((route) =>
                        <ListItem key={"drawer-route-" + route.name}>
                            <ListItemButton
                                sx={{
                                    color: "custom.main",
                                }}
                                className="drawer-item"
                                onClick={() => { navigate(route.location); setOpen(false); }}
                            >
                                { route.name }
                            </ListItemButton>
                        </ListItem>
                    )
                }
                </List>
        </StyledDrawer>
        <Paper
            sx={{
                width: "98vw",
                m: "auto",
                backgroundColor: "#edebeb"
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    pt: 5
                }}
            >
                <Typography
                    sx={{
                        fontFamily: "titleFont",
                        m: "auto",
                        fontWeight: "bold",
                        fontSize: {xs: "2.5rem", md: "4rem"}
                    }}
                    color="custom2.main"
                    className="widget"
                    onClick={() => { navigate("/produse"); }}
                >
                    Inapoi La Produse
                </Typography>
            </Box>
            <Box
                sx={{
                    display: "flex",
                    pt: 5
                }}
            >
                <Typography
                    sx={{
                        fontFamily: "titleFont",
                        m: "auto",
                        fontWeight: "bold",
                        fontSize: {xs: "2.5rem", md: "4rem"},
                        textAlign: "center"
                    }}
                    color="custom2.main"
                >
                    ~~{ product.name }~~
                </Typography>
            </Box>
            <Divider color="custom2.main" sx={{width: {xs: "90%", lg: "50%"}, mx: "auto", my: 2}} />
            <Box
                sx={{
                    display: "flex",
                    pt: 0,
                    maxWidth: {xs: "90%", lg: "50%"},
                    m: "auto",
                    mb: 2,
                    textAlign: "center"
                }}
                variant="h6"
            >
                <Typography
                    sx={{
                        m: "auto",
                        fontWeight: "bold",
                        textAlign: "center"
                    }}
                    color="custom2.main"
                >
                    { product.description }
                </Typography>
            </Box>
            <Divider color="custom2.main" sx={{width: {xs: "90%", lg: "50%"}, mx: "auto", my: 2}} />
            <Grid container xs={10} sm={10} md={8} lg={5}
                sx={{
                    m: "auto"
                }}
            >
                <Grid item xs={9} sm={5} md={5} lg={5}
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        minHeight: {xs: "40vh", sm: "30vh", md: "40vh", lg: "45vh"},
                        m: "auto",
                        my: 2,
                        gap: 5
                    }}
                >
                    <img src={product.image} alt={product.name} height="90%" width="100%" />
                </Grid>
                <Grid item xs={12} sm={5} md={5} lg={3}>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        <Typography
                            color="custom2.main"
                            sx={{
                                my: 2,
                                mx: "auto"
                            }}
                            variant="h6"
                        >
                            Ingrediente:
                        </Typography>
                        {
                            product.ingredients.map((ingredient) => 
                                <Typography
                                    key={"ingredient-" + ingredient}
                                    sx={{
                                        textAlign: "center"
                                    }}
                                >
                                    { ingredient }
                                </Typography>
                            )
                        }
                        {
                            product.post &&
                            <Box
                                sx={{
                                    backgroundColor: "success.main",
                                    borderRadius: "8px",
                                    display: "flex",
                                    mt: 2
                                }}
                            >
                                <Box
                                    sx={{   
                                        display: "flex",
                                        flexDirection: "row",
                                        m: "auto",
                                        py: 1
                                    }}
                                >
                                    <Check sx={{color: "#ffffff"}} />
                                    <Typography
                                        sx={{
                                            fontWeight: "bold",
                                            textAlign: "center",
                                            color: "#ffffff",
                                            ml: 1
                                        }}
                                    >
                                        De post
                                    </Typography>
                                </Box>
                            </Box>
                        }
                        {
                            product.new &&
                            <Box
                                sx={{
                                    backgroundColor: "#e5d96b",
                                    borderRadius: "8px",
                                    display: "flex",
                                    mt: 2
                                }}
                            >
                                <Box
                                    sx={{   
                                        display: "flex",
                                        flexDirection: "row",
                                        m: "auto",
                                        py: 1
                                    }}
                                >
                                    <Check sx={{color: "#ffffff"}} />
                                    <Typography
                                        sx={{
                                            fontWeight: "bold",
                                            textAlign: "center",
                                            color: "#ffffff",
                                            ml: 1
                                        }}
                                    >
                                        NOU!
                                    </Typography>
                                </Box>
                            </Box>
                        }
                        <TextField
                            label="Varianta"
                            value={selectedVariant}
                            onChange={(e) => { setSelectedVariant(e.target.value) }}
                            select
                            sx={{
                                m: "auto",
                                my: 5,
                                width: "10rem"
                            }}
                        >
                            {
                                product.variants.map((variant, index) =>
                                    <MenuItem key={variant} value={variant}>
                                        { variant.name } - { variant.price }.00 lei
                                    </MenuItem>
                                )
                            }
                        </TextField>
                        <TextField
                            id="outlined-number"
                            label="Cantitate"
                            value={quantity}
                            onChange={(e) => {  
                                if((e.target.value > 0 ||
                                    e.target.value === "") &&
                                    e.target.value !== "-"
                                    ) {
                                        setQuantity(e.target.value); 
                                    }
                            }}
                            type="number"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            sx={{
                                mb: 5,
                                mx: "auto",
                                width: "10rem"
                            }}
                        />
                        <Button
                            color="custom2"
                            variant="contained"
                            startIcon={<Add />}
                            sx={{
                                color: "#ffffff",
                                mb: 5
                            }}
                            size="large"
                            onClick={() => { addToCart({
                                product: product,
                                quantity: quantity,
                                variant: selectedVariant
                            }); }}
                        >
                            Adauga in cos
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        </Paper>
        </React.Fragment>
    );
}