import { Add } from "@mui/icons-material";
import { Collapse, List, ListItem, ListItemButton, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import items from "./produse.json"

export function ListItems({ addItem }) {

    const [open, setOpen] = React.useState(new Array(items.categories.length).fill(false));

    function toggleCollapse(index) {
        const newOpen = open.slice(0);
        newOpen[index] = !open[index];
        setOpen(newOpen);
    }

    return(
        <List>
            {
                items.categories.map((category, index) => 
                    <React.Fragment
                        key={"fragment-" + category}
                    >
                        <ListItem
                            key={"button-" + category}
                        >
                            <ListItemButton 
                                onClick={() => { toggleCollapse(index); }}
                            >
                                <Typography
                                >
                                    { category }
                                </Typography>
                            </ListItemButton> 
                        </ListItem> 
                        <Collapse 
                            key={"collapse-" + category}
                            in={open[index]}
                            timeout="auto"
                            unmountOnExit
                        >   
                            {
                                items.items[category].map(item =>
                                    <ListItemButton
                                        key={"item-button-" + item.name + "-" + item.quantity}
                                        onClick={() => { addItem(item); }}
                                    >
                                        <Box
                                            sx={{
                                                display: "flex",
                                                flexDirection: "column"
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    flexDirection: "row"
                                                }}
                                            >
                                                <Add color="custom" />
                                                <Typography
                                                    color="custom.main"
                                                    sx={{
                                                        mx: "1rem"
                                                    }}
                                                >
                                                    { item.name }
                                                </Typography>
                                            </Box>
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    flexDirection: "row"
                                                }}
                                            >
                                                <Add color="custom" sx={{opacity: "0"}} />
                                                <Typography
                                                    color="custom.main"
                                                    sx={{
                                                        mx: "1rem"
                                                    }}
                                                >
                                                    { item.quantity }
                                                </Typography>
                                                <Typography
                                                    color="custom.main"
                                                >
                                                    { item.price }lei
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </ListItemButton>
                                )
                            }
                        </Collapse>
                    </React.Fragment>
                )
            }
        </List>
    );
}