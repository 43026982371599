import { MenuBook } from "@mui/icons-material";
import { AppBar, IconButton, styled } from "@mui/material";

const MenuButtonAppBar = styled(AppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
  })(({ theme, open }) => ({
    width: "4rem",
    left: 0,
    backgroundColor: "#2b2a2a",
    top: "5rem",
    boxShadow: "0px 5px 20px 1px " + theme.palette.custom.main,
    borderTopRightRadius: "4px",
    borderBottomRightRadius: "4px",
    border: "1px solid " + theme.palette.custom.main,
    zIndex: theme.zIndex.drawer,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.leavingScreen,
    }),
  }));

export function StyledMenuButton({ open, toggleDrawer }) {
    return(
        <MenuButtonAppBar open={open}>
            <IconButton id="main-drawer-toggle" onClick={() => { toggleDrawer() }}>
                <MenuBook color="custom" fontSize="large" />
            </IconButton>
        </MenuButtonAppBar>
    );
}