import { Home, LocalPhone, Mail } from "@mui/icons-material";
import { Box, Paper, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import routes from "./routes.json";

export default function StyledFooter() {

    const navigate = useNavigate();

    return(
        <Box
            sx={{
                minHeight: "50vh",
                width: "100vw",
                backgroundColor: "#333",
                mt: 5
            }}
        >
            <Paper
                sx={{
                    backgroundImage: "url(/footer_bg.jpg)",
                    backgroundPosition: "20% 30%",
                    height: "80%",
                    width: "100%",
                }}
            >
                <Box
                    sx={{
                        background: "rgba(0, 0, 0, 0) url(/pattern-overlay.png) repeat scroll 0 0",
                        minHeight: "100%",
                        minWidth: "100%",
                        content: '""',
                        display: "flex",
                        flexDirection: "column",
                        p: 3
                    }}
                >
                    <Typography
                        sx={{
                            fontFamily: "titleFont",
                            m: "auto",
                            fontSize: "4rem",
                            color: "primary.contrastText"
                        }}
                    >
                        Ne gasiti aici
                    </Typography>
                    <Typography
                        sx={{
                            m: "auto",
                            color: "primary.contrastText",
                            textAlign: "center"
                        }}
                        variant="h6"
                    >
                        SC ARMICAR SERV PRODEX SRL
                    </Typography>
                    <Box
                        component="span"
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            m: "auto",
                            mt: 2
                        }}
                    >
                        <Home
                            sx={{
                                color: "primary.contrastText",
                                fontSize: "2rem"
                            }}
                        />
                        <Typography
                            sx={{
                                color: "primary.contrastText",
                                mt: "auto",
                                ml: 2,
                                textAlign: "center"
                            }}
                            
                        >
                            STR. CARPATI, Nr.6, PETROŞANI
                        </Typography>
                    </Box>
                    <Box
                        component="span"
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            m: "auto"
                        }}
                    >
                        <LocalPhone
                            sx={{
                                color: "primary.contrastText",
                                fontSize: "2rem"
                            }}
                        />
                        <Typography
                            sx={{
                                color: "primary.contrastText",
                                mt: "auto",
                                ml: 2,
                                textAlign: "center"
                            }}
                            
                        >
                            0744 395 102
                        </Typography>
                    </Box>
                    <Box
                        component="span"
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            m: "auto",
                            pb: 5
                        }}
                    >
                        <Mail
                            sx={{
                                color: "primary.contrastText",
                                fontSize: "2rem"
                            }}
                        />
                        <Typography
                            sx={{
                                color: "primary.contrastText",
                                mt: "auto",
                                ml: 2,
                                textAlign: "center"
                            }}
                            
                        >
                            hotelparang@gmail.com
                        </Typography>
                    </Box>
                </Box>
            </Paper>
            <Box
                sx={{
                    width: "100vw",
                    height: "2.5%",
                    backgroundColor: "#e5d96b"
                }}
            />
            <Box
                sx={{
                    width: "100vw",
                    height: "17.5%",
                    display: "flex",
                    flexDirection: "column"
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        m: "auto",
                        gap: 1,
                        pt: 1
                    }}
                >
                {
                    routes.footer.map((route) => 
                        <Typography
                            key={"route-" + route.name}
                            sx={{
                                color: "primary.contrastText",
                                cursor: "pointer",
                                textAlign: "center"
                            }}
                            onClick={() => { navigate(route.location); }}
                        >
                            { route.name }
                        </Typography>
                    )
                }
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        m: "auto",
                        pt: 2
                    }}
                >
                    <Typography
                        sx={{
                            color: "primary.contrastText",
                            textAlign: "center"
                        }}
                    >
                        Copyright MB Software { (new Date()).getFullYear() }© All rights reserved.
                    </Typography>
            </Box>
            </Box>
        </Box>
    );
}