import { ChevronLeft } from "@mui/icons-material";
import { Divider, IconButton, List, ListItem, ListItemButton, Toolbar } from "@mui/material";
import React from "react";
import { Comenzi } from "../../Comenzi";
import { ListItems } from "../../ListItems";
import { StyledDrawer } from "../../StyledDrawer";
import routes from "../../routes.json"
import { useNavigate } from "react-router-dom";

export default function ComenziPage({ open, setOpen, toggleDrawer }) {

    React.useEffect(() => {
      window.scrollTo(0, 0)
    }, [])

    const [items, setItems] = React.useState([]);
    const navigate = useNavigate();

    function addItem(item) {
        const newItems = [];
        let itemExists = false;
    
        items.forEach(existingItem => {
          if(existingItem.item.name === item.name &&
              existingItem.item.quantity === item.quantity
            ) {
              itemExists = true;
              newItems.push({
                "item": item,
                "quantity": existingItem.quantity + 1
              })
            } else {
              newItems.push(existingItem);
            }
        })
    
        if(!itemExists) {
          newItems.push({
            "item": item,
            "quantity": 1
          });
        }
        setItems(newItems);
      }

    return(
        <React.Fragment>
            <StyledDrawer variant="permanent" open={open} id="drawer-main" >
            <Toolbar
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    px: [1],
                }}
                >
                <IconButton onClick={toggleDrawer}>
                    <ChevronLeft color="custom" />
                </IconButton>
                </Toolbar>
                <Divider color="#e5d96b" sx={{width: "90%", mx: "auto"}} />
                <ListItems addItem={addItem} />
                <Divider color="#e5d96b" sx={{width: "90%", mx: "auto"}} />
                <List>
                {
                    routes.drawer.map((route) =>
                        <ListItem key={"drawer-route-" + route.name}>
                            <ListItemButton
                                sx={{
                                    color: "custom.main",
                                }}
                                className="drawer-item"
                                onClick={() => { navigate(route.location); setOpen(false); }}
                            >
                                { route.name }
                            </ListItemButton>
                        </ListItem>
                    )
                }
                </List>
            </StyledDrawer>
            <Comenzi items={items} setItems={setItems} setOpen={setOpen} />
        </React.Fragment>
    )
}