import { ChevronRight, Close } from "@mui/icons-material";
import { Box, Button, Divider, Drawer, IconButton, List, ListItem, styled, Toolbar, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

const drawerWidth = 280;

const ShoppingCartStyledDrawer = styled(Drawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
      '& .MuiDrawer-paper': {
        position: 'fixed',
        top: 0,
        left: "calc(100% - " + drawerWidth +  "px)",
        whiteSpace: 'nowrap',
        backgroundColor: "#333",
        color: theme.palette.custom.contrastText,
        boxShadow: "0px 5px 20px 1px " + theme.palette.custom.main,
        width: drawerWidth,
        transition: theme.transitions.create('left', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
        boxSizing: 'border-box',
        ...(!open && {
          overflowX: 'hidden',
          transition: theme.transitions.create('left', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
          left: "100%"
        }),
      },
    }),
  );

export default function ShoppingCartDrawer({ shoppingCartOpen, setShoppingCartOpen, shoppingCart, getShoppingCartPrice, removeItem }) {
    
    const navigate = useNavigate();
    
    return(
        <ShoppingCartStyledDrawer open={shoppingCartOpen} variant="permanent" id="cart-drawer">
        <Toolbar
          sx={{
              display: 'flex',
              alignItems: 'center',
              px: [1],
          }}
        >
        <IconButton onClick={() => { setShoppingCartOpen(false); }}>
            <ChevronRight color="custom" />
        </IconButton>
        </Toolbar>
        <Divider color="#e5d96b" sx={{width: "90%", mx: "auto"}} />
        <List>
          {
            shoppingCart.map((item) => 
              <ListItem
                sx={{
                  m: "auto"
                }}
                key={"shopping-cart-" + item.product.name + item.variant.name}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    m: "auto",
                    whiteSpace: "initial"
                  }}
                >
                  <Typography
                    sx={{
                      m: "auto",
                      textAlign: "center",
                    }}
                    className="widget"
                    onClick={() => { 
                        navigate("/detalii?category=" + item.product.category + "&product=" + item.product.index); 
                        setShoppingCartOpen(false);
                    }}
                  >
                    { item.product.name } { item.variant.name } 
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row"
                    }}
                  >
                    <Typography
                      sx={{
                        m: "auto",
                        textAlign: "center"
                      }}
                    >
                      x{ item.quantity } - { item.quantity * item.variant.price }.00 lei
                    </Typography>
                    <Close
                      sx={{
                        color: "error.main",
                        fontSize: "2rem",
                        cursor: "pointer"
                      }}
                      onClick={() => { removeItem(item); }} 
                    />
                  </Box>
                </Box>
              </ListItem>
            )
          }
        </List>
        {
          shoppingCart.length > 0 &&
          <React.Fragment>
            <Divider color="#e5d96b" sx={{width: "90%", mx: "auto"}} />
            <Typography
              sx={{
                mx: "auto",
                my: 2,
                color: "custom.main"
              }}
              variant="h6"
            >
              Total: { getShoppingCartPrice() }.00 lei
            </Typography>
            <Divider color="#e5d96b" sx={{width: "90%", mx: "auto"}} />
            <Button
              color="custom"
              sx={{
                mt: 2,
                width: "80%",
                mx: "auto"
              }}
              onClick={() => { navigate("/finalizare"); setShoppingCartOpen(false); }}
              variant="contained"
            >
              Finalizeaza Comanda
            </Button>
          </React.Fragment>
        }
        {
            shoppingCart.length === 0 &&
            <Box
                sx={{
                display: "flex",
                flexDirection: "column",
                mx: "auto",
                mt: 5,
                whiteSpace: "initial"
                }}
            >
                <Typography
                    sx={{
                        mx: "auto",
                        textAlign: "center",
                    }}
                    color="custom.main"
                >
                Cosul de cumparaturi este gol.
                </Typography>
            </Box>
        }
      </ShoppingCartStyledDrawer>
    )
}