import { ChevronLeft, Send } from "@mui/icons-material";
import { Divider, IconButton, Toolbar, Box, Typography, Paper, Table, TableHead, TableCell, TableBody, TableRow, Button, Radio, FormControlLabel, Grid, Dialog, DialogTitle, List, ListItem, ListItemButton } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { StyledDrawer } from "../../StyledDrawer";
import { StyledTextField } from "../../StyledTextField";
import emailjs from "@emailjs/browser";
import routes from "../../routes.json"

export default function FinalizarePage({open, setOpen, toggleDrawer, shoppingCart, getShoppingCartTotal, setShoppingCart}) {

    const [showForm, setShowForm] = React.useState(false);
    const [name, setName] = React.useState("");
    const [phone, setPhone] = React.useState("");
    const [details, setDetails] = React.useState("");
    const [address, setAddress] = React.useState("");
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [dialogStatus, setDialogStatus] = React.useState("");
    const navigate = useNavigate();
    const freeShippingThreshold = 240;

    React.useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    function showErrorDialog() {
        setDialogOpen(true);
        setDialogStatus("bad-form");
    }

    function showSendErrorDialog() {
        setDialogOpen(true);
        setDialogStatus("send-fail");
    }

    function showSuccessDialog() {
        setDialogOpen(true);
        setDialogStatus("success");
    }

    function getDialogMessage() {
        if(dialogStatus === "bad-form") {
            if(!validatePhone()) {
                return "Va rugam introduceti un numar de telefon mobil corect."
            }

            if(!validateName()) {
                return "Va rugam introduceti un nume."
            }

            if(!validateAddress()) {
                return "Va rugam introduceti o adresa."
            }
        }

        if(dialogStatus === "success") {
            return "Comanda a fost trimisa cu succes!";
        }

        if(dialogStatus === "send-fail") {
            return "Din pacate, a aparut o problema. Va rugam sa incercati din nou.";
        }
    }

    function validatePhone() {
        if(phone.length === 10) {
            if(phone[0] === "0" && phone[1] === "7") {
                return true;
            }
        }

        return false;
    }

    function validateName() {
        if(name.length > 2) {
            return true;
        }

        return false;
    }

    function validateAddress() {
        if(address.length > 10) {
            return true;
        }

        return false;
    }

    function canSubmit() {
        return validatePhone() && validateName() && validateAddress();
    }

    function getShippingCost() {
        const shoppingCartTotal = getShoppingCartTotal();

        if(shoppingCartTotal >= freeShippingThreshold) {
            return 0;
        }

        return 20;
    }

    function mashData() {
        let products = "";
        shoppingCart.forEach((item) => {
            products += "Produs: " + item.product.name + ", Varianta: " + item.variant.name + ", Cantitate: " +  item.quantity + "buc";
        })
        const finalName = "Nume: " + name;
        const finalPhone = "Telefon: " + phone;
        const finalAddress = "Adresa: " + address;
        const finalDetails = "Detalii: " + details;

        return products + "~~~" + finalName + "~~~" + finalPhone + "~~~" + finalAddress + "~~~" + finalDetails + "~~~Total: " + getShoppingCartTotal();
    }

    const sendEmail = (e) => {
        e.preventDefault();
        emailjs.sendForm("service_251tana", "template_poglide", e.target, "ziO2QMbsBBShza9Mb")
            .then(() => {
                showSuccessDialog();
                setShoppingCart([]);
                localStorage.removeItem("shopping-cart");
                setTimeout(() => {
                    navigate("/comanda-trimisa")
                }, 2500)
            }
            , () => {
                showSendErrorDialog();
            })
    }

    return(
        <React.Fragment>
            <StyledDrawer variant="permanent" open={open} id="drawer-main" >
                <Toolbar
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        px: [1],
                    }}
                >
                <IconButton onClick={toggleDrawer}>
                    <ChevronLeft color="custom" />
                </IconButton>
                </Toolbar>
                <Divider color="#e5d96b" sx={{width: "90%", mx: "auto"}} />
                <List>
                {
                    routes.drawer.map((route) =>
                        <ListItem key={"drawer-route-" + route.name}>
                            <ListItemButton
                                sx={{
                                    color: "custom.main",
                                }}
                                className="drawer-item"
                                onClick={() => { navigate(route.location); setOpen(false); }}
                            >
                                { route.name }
                            </ListItemButton>
                        </ListItem>
                    )
                }
                </List>
            </StyledDrawer>
            <Paper
                sx={{
                    width: "98vw",
                    m: "auto",
                    backgroundColor: "#edebeb"
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        pt: 5
                    }}
                >
                    <Typography
                        sx={{
                            fontFamily: "titleFont",
                            m: "auto",
                            fontWeight: "bold",
                            fontSize: {xs: "2.5rem", md: "4rem"},
                            textAlign: "center"
                        }}
                        color="custom2.main"
                    >
                        ~~Sumar Comanda~~
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        px: 1,
                        pb: 5,
                        maxWidth: {xs: "100%", md: "80%", lg: "50%"},
                        m: "auto"
                    }}
                >
                    <Table
                    >
                        <TableHead>
                            <TableCell
                                align="center"
                                sx={{
                                    color: "custom2.main",
                                    fontWeight: "bold",
                                    borderBottomColor: "custom2.main"
                                }}
                            >
                                Produs
                            </TableCell>
                            <TableCell
                                align="center"
                                sx={{
                                    color: "custom2.main",
                                    fontWeight: "bold",
                                    borderBottomColor: "custom2.main"
                                }}
                            >
                                Cantitate
                            </TableCell>
                            <TableCell
                                align="center"
                                sx={{
                                    color: "custom2.main",
                                    fontWeight: "bold",
                                    borderBottomColor: "custom2.main"
                                }}
                            >
                                Pret Unitar
                            </TableCell>
                            <TableCell
                                align="center"
                                sx={{
                                    color: "custom2.main",
                                    fontWeight: "bold",
                                    borderBottomColor: "custom2.main"
                                }}
                            >
                                Pret Total
                            </TableCell>
                        </TableHead>
                        <TableBody>
                            {
                                shoppingCart.map((item) => 
                                    <TableRow key={item.product.name + "-" + item.variant.name}>
                                        <TableCell
                                            align="center"
                                            sx={{
                                                borderBottomColor: "custom2.main"
                                            }}
                                        >
                                            { item.product.name } ({ item.variant.name})
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                            sx={{
                                                borderBottomColor: "custom2.main"
                                            }}
                                        >
                                            { item.quantity } buc
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                            sx={{
                                                borderBottomColor: "custom2.main"
                                            }}
                                        >
                                            { item.variant.price }.00 lei
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                            sx={{
                                                borderBottomColor: "custom2.main"
                                            }}
                                        >
                                            { item.variant.price * item.quantity }.00 lei
                                        </TableCell>
                                    </TableRow>
                                )
                            }
                            <TableRow>
                                <TableCell
                                    align="center"
                                    sx={{
                                        borderBottomColor: "custom2.main"
                                    }}
                                >
                                    Livrare
                                </TableCell>
                                <TableCell
                                    align="center"
                                    sx={{
                                        borderBottomColor: "custom2.main"
                                    }}
                                >
                                    -
                                </TableCell>
                                <TableCell
                                    align="center"
                                    sx={{
                                        borderBottomColor: "custom2.main"
                                    }}
                                >
                                    { getShippingCost() > 0 ? "-" : "Gratuit" } 
                                </TableCell>
                                <TableCell
                                    align="center"
                                    sx={{
                                        borderBottomColor: "custom2.main"
                                    }}
                                >
                                    { getShippingCost() > 0 ? getShippingCost() + " lei" : "Gratuit" } 
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                    <Typography
                        sx={{
                            m: "auto",
                            mt: 5,
                            fontWeight: "bold"
                        }}
                        color="custom2.main"
                        variant="h6"
                    >
                        Total: { getShoppingCartTotal() + getShippingCost() }.00 lei
                    </Typography>
                    {
                        !showForm &&
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column"
                            }}
                        >
                            {
                                getShippingCost() > 0 &&
                                <Typography
                                    sx={{
                                        m: "auto",
                                        mt: 5,
                                        fontWeight: "bold"
                                    }}
                                    color="error.main"
                                    variant="h6"
                                >
                                    Va rugam sa adaugati minimum 4kg de produse pentru a putea finaliza comanda.
                                </Typography>
                            }
                            <Button
                                color="custom"
                                variant="contained"
                                sx={{
                                    width: "15rem",
                                    m: "auto",
                                    mt: 5
                                }}
                                onClick={() => { setShowForm(true); }}
                                disabled={getShippingCost() > 0}
                            >
                                Catre Finalizare
                            </Button>
                        </Box>
                    }
                </Box>
                {
                    showForm &&
                    <React.Fragment>
                    <Grid container xs={10} sm={8}
                        sx={{
                            m: "auto"
                        }}
                    >
                    <Grid item md={1} lg={1} />
                    <Grid item xs={12} md={4} lg={4}
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        <StyledTextField 
                            sx={{
                                mb: 3,
                                mt: 6
                            }}
                            variant="outlined"
                            color="custom2"
                            label="Nume"
                            name="name"
                            value={name}
                            onChange={(e) => { setName(e.target.value) }}
                            focused
                            inputProps={{ style: { color: "#7f390a" } }}
                        />
                        <StyledTextField 
                            sx={{
                                my: 3
                            }}
                            variant="outlined"
                            color="custom2"
                            label="Telefon"
                            name="phone"
                            value={phone}
                            onChange={(e) => { setPhone(e.target.value) }}
                            focused
                            inputProps={{ style: { color: "#7f390a" } }}
                        />
                        <StyledTextField 
                            sx={{
                                my: 3
                            }}
                            variant="outlined"
                            color="custom2"
                            label="Adresa"
                            name="address"
                            value={address}
                            onChange={(e) => { setAddress(e.target.value) }}
                            focused
                            inputProps={{ style: { color: "#7f390a" } }}
                        />
                        <StyledTextField 
                            sx={{
                                my: 3
                            }}
                            variant="outlined"
                            color="custom2"
                            label="Detalii Suplimentare"
                            name="details"
                            value={details}
                            onChange={(e) => { setDetails(e.target.value) }}
                            focused
                            multiline
                            rows={5}
                            inputProps={{ style: { color: "#7f390a" } }}
                        />
                    </Grid>
                    <Grid item md={2} lg={2} />
                    <Grid
                        item xs={12} md={4} lg={4}
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        <Paper
                            sx={{
                                width: "100%",
                                mb: 2,
                                mt: 6,
                                py: 3,
                                backgroundColor: "#cfcccc",
                                display: "flex",
                                flexDirection: "column"
                            }}
                            elevation={5}
                        >
                            <Typography
                                color="custom2"
                                variant="h6"
                                sx={{
                                    fontWeight: "bold",
                                    textAlign: "center",
                                    mb: 3
                                }}
                            >
                                Livrare
                            </Typography>
                            <FormControlLabel 
                                sx={{
                                    m: "auto"
                                }}
                                value="" 
                                control={<Radio color="custom2" checked="true" />} 
                                label="Curier rapid"    
                            />
                        </Paper>
                        <Paper
                            sx={{
                                width: "100%",
                                my: 5,
                                py: 3,
                                backgroundColor: "#cfcccc",
                                display: "flex",
                                flexDirection: "column"
                            }}
                            elevation={5}
                        >
                            <Typography
                                color="custom2"
                                variant="h6"
                                sx={{
                                    fontWeight: "bold",
                                    textAlign: "center",
                                    mb: 3
                                }}
                            >
                                Metoda de plata
                            </Typography>
                            <FormControlLabel 
                                sx={{
                                    m: "auto"
                                }}
                                value="" 
                                control={<Radio color="custom2" checked="true" />} 
                                label="Ramburs la livrare"    
                            />
                        </Paper>
                    </Grid>
                    <Grid item md={1} lg={1} />
                    </Grid>
                    <Box
                        sx={{
                            display: "flex",
                            m: "auto",
                            flexDirection: "column",
                            mb: 5
                        }}
                    >
                        <Typography
                            sx={{
                                m: "auto",
                                textAlign: "center"
                            }}
                        >
                            Datele dumneavoastra personale vor fi folosite pentru a procesa comanda. Puteti citi mai multe despre asta in&nbsp;
                            <Typography component="span"
                                color="custom2.main"
                                sx={{
                                    cursor: "pointer"
                                }}
                                onClick={() => { navigate("/confidentialitate"); }}
                            >
                                politica de confidentialitate
                            </Typography>
                            .
                        </Typography>
                        {
                            canSubmit() &&
                            <form
                                onSubmit={sendEmail}
                            >
                                <StyledTextField 
                                    sx={{
                                        my: 3,
                                        display: "none"
                                    }}
                                    variant="outlined"
                                    color="custom"
                                    name="items"
                                    value={mashData()}
                                    focused
                                    inputProps={{ style: { color: "#e5d96b" } }}
                                />
                                <Button
                                    sx={{
                                        width: "15rem",
                                        m: "auto",
                                        mt: 5
                                    }}
                                    color="custom"
                                    variant="contained"
                                    startIcon={<Send />}
                                    type="submit"
                                >
                                    Trimite Comanda
                                </Button>
                            </form>
                        }
                        {
                            !canSubmit() &&
                            <Button
                                sx={{
                                    width: "15rem",
                                    m: "auto",
                                    mt: 5
                                }}
                                color="custom"
                                variant="contained"
                                startIcon={<Send />}
                                onClick={() => { showErrorDialog(); }}
                            >
                                Trimite Comanda
                            </Button>
                        }
                    </Box>
                    </React.Fragment>
                }
                <Dialog
                    open={dialogOpen}
                    onClose={() => { setDialogOpen(false); }}
                >
                    <DialogTitle
                        sx={{
                            p: 5,
                            backgroundColor: "#e5d96b",
                            textAlign: "center"
                        }}
                    >
                        { getDialogMessage() }
                    </DialogTitle>
                </Dialog>
            </Paper>
        </React.Fragment>
    )
}