import { ArrowBackIos, ArrowForwardIos, Check, ChevronLeft, Sort, Tune } from "@mui/icons-material"
import { Box, Button, Checkbox, Divider, FormControlLabel, Grid, IconButton, List, ListItem, ListItemButton, Menu, MenuItem, Paper, TextField, Toolbar, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import produseShop from "../../produse_shop.json"
import { StyledDrawer } from "../../StyledDrawer";
import routes from "../../routes.json"

export default function ProdusePage({ open, setOpen, toggleDrawer }) {

    const navigate = useNavigate();
    const [selectedCategory, setSelectedCategory] = React.useState("");
    const [filteredProducts, setFilteredProducts] = React.useState([])
    const [currentPage, setCurrentPage] = React.useState(1);
    const [pages, setPages] = React.useState([]);
    const [orderBy, setOrderBy] = React.useState("Implicit");
    const [searchBy, setSearchBy] = React.useState("");
    const [filters, setFilters] = React.useState([]);
    const [checkedFilters, setCheckedFilters] = React.useState([false, false]);
    const pageSize = 6;
    const [anchorElOrder, setAnchorElOrder] = React.useState(null);
    const [anchorElFilter, setAnchorElFilter] = React.useState(null);
    const orderByMenuOpen = Boolean(anchorElOrder);
    const filterMenuOpen = Boolean(anchorElFilter);

    function changeCategory(category) {
        setSelectedCategory(category);
        setCheckedFilters([false, false]);
        setFilters([]);
        setSearchBy("");
        setOrderBy("Implicit");
        setCurrentPage(1);
    }

    const handleClickFilter = (event) => {
        setAnchorElFilter(event.currentTarget);
    };

    const handleClickOrder = (event) => {
        setAnchorElOrder(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorElOrder(null);
        setAnchorElFilter(null);
    };

    React.useEffect(() => {
        window.scrollTo(0, 0)
    }, [selectedCategory]);

    function updateCheckedFilters(changedFilter) {
        const newCheckedFilters = checkedFilters.slice(0);

        if(changedFilter === "post") {
            newCheckedFilters[0] = !newCheckedFilters[0];
        }

        if(changedFilter === "new") {
            newCheckedFilters[1] = !newCheckedFilters[1];
        }

        return newCheckedFilters;
    }

    function toggleFilter(productFilter) {
        let filterExists = false;
        const newFilters = [];
        console.log(productFilter)

        filters.forEach((existingFilter) => {
            if(existingFilter === productFilter) {
                filterExists = true;
            }
        })

        if(filterExists) {
            filters.forEach((existingFilter) => {
                if(existingFilter !== productFilter) {
                    newFilters.push(existingFilter);
                }
            })
        } else {
            filters.forEach((existingFilter) => {
                newFilters.push(existingFilter);
            })
            newFilters.push(productFilter);
        }

        setCheckedFilters(updateCheckedFilters(productFilter))
        setFilters(newFilters);
    }

    function matchFilters(product) {
        if(product.name.toLowerCase().includes(searchBy.toLowerCase())) {
            if(filters.length > 0) {
                let filtersPass = true;

                filters.forEach((productFilter) => {
                    if(!product[productFilter]) {
                        filtersPass = false;
                    }
                })

                return filtersPass;
            }

            return true;
        }

        return false;
    }

    function matchFilter(product, filterToMatch) {
        return product[filterToMatch];
    }

    React.useEffect(() => {
        const newFilteredProducts = [];

        if(selectedCategory === "") {
            Object.keys(produseShop.products).forEach((category) => {
                produseShop.products[category].forEach((product) => {
                    if(matchFilters(product)) {
                        newFilteredProducts.push(product);
                    }
                })
            });
        } else {
            produseShop.products[selectedCategory].forEach((product) => {
                if(matchFilters(product)) {
                    newFilteredProducts.push(product);
                }
            })
        }

        if(orderBy === "Alfabetic") {
            newFilteredProducts.sort((a, b) => { return a.name.localeCompare(b.name) })
        }

        if(orderBy === "Ieftine") {
            newFilteredProducts.sort((a, b) => { return a.variants[0].price - b.variants[0].price })
        }

        if(orderBy === "Scumpe") {
            newFilteredProducts.sort((a, b) => { return b.variants[0].price - a.variants[0].price })
        }

        setFilteredProducts(newFilteredProducts);
        setPages(Array.from(Array(Math.ceil(newFilteredProducts.length / pageSize)).keys()));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderBy, searchBy, filters, selectedCategory])
    
    function getPriceRange(product) {
        const variants = product.variants.slice(0);
        variants.sort((a, b) => {return a.price - b.price});
        if(variants.length === 1) {
            return variants[0].price;
        } else {
            return variants[0].price + ".00 - " + variants[variants.length - 1].price;
        }
    }

    function getVariantsRange(product) {
        const variants = product.variants.slice(0);
        variants.sort((a, b) => {return a.price - b.price});
        if(variants.length === 1) {
            return variants[0].name;
        } else {
            return variants[0].name + " - " + variants[variants.length - 1].name;
        }
    }

    function getPageContents(productsToTrim) {
        const pageContents = [];

        for(let i = 0; i < pageSize; i ++) {
            pageContents.push(productsToTrim[(currentPage - 1) * pageSize + i])
        }

        return pageContents;
    }

    function getCountForFilter(filterToCount) {
        const matchingProducts = [];
        if(selectedCategory === "") {
            Object.keys(produseShop.products).forEach((category) => {
                produseShop.products[category].forEach((product) => {
                    if(matchFilter(product, filterToCount)) {
                        matchingProducts.push(product);
                    }
                })
            });
        } else {
            produseShop.products[selectedCategory].forEach((product) => {
                if(matchFilter(product, filterToCount)) {
                    matchingProducts.push(product);
                }
            })
        }

        return matchingProducts.length;
    }

    return(
        <React.Fragment>
        <StyledDrawer variant="permanent" open={open} id="drawer-main" >
            <Toolbar
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    px: [1],
                }}
            >
            <IconButton onClick={toggleDrawer}>
                <ChevronLeft color="custom" />
            </IconButton>
            </Toolbar>
            <Divider color="#e5d96b" sx={{width: "90%", mx: "auto"}} />
            <List>
                {
                    routes.drawer.map((route) =>
                        <ListItem key={"drawer-route-" + route.name}>
                            <ListItemButton
                                sx={{
                                    color: "custom.main",
                                }}
                                className="drawer-item"
                                onClick={() => { navigate(route.location); setOpen(false); }}
                            >
                                { route.name }
                            </ListItemButton>
                        </ListItem>
                    )
                }
                </List>
        </StyledDrawer>
        <Paper
            sx={{
                width: "98vw",
                m: "auto",
                backgroundColor: "#edebeb"
            }}
        >
            {
                selectedCategory === "" &&
                <React.Fragment>
                <Box
                    sx={{
                        display: "flex",
                        pt: 5
                    }}
                >
                    <Typography
                        sx={{
                            fontFamily: "titleFont",
                            m: "auto",
                            fontWeight: "bold",
                            fontSize: {xs: "2.5rem", md: "4rem"},
                            textAlign: "center"
                        }}
                        color="custom2.main"
                    >
                        ~~Categorii De Produse~~
                    </Typography>
                </Box>
                <Grid container xs={10} md={12} lg={8}
                    sx={{
                        m: "auto",
                        pt: 5
                    }}
                >
                    {
                        produseShop.categories.map((produs) => 
                            <Grid item xs={12} sm={6} md={4} lg={4}
                                key={"product-" + produs.name}
                            >
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        minHeight: {xs: "40vh", sm: "30vh", md: "38vh", lg: "45vh"},
                                        width: {xs: "45vw", sm: "27vw", md: "22vw", lg: "15vw"},
                                        m: "auto",
                                        my: 2
                                    }}
                                    className="widget"
                                    onClick={() => { changeCategory(produs.name); }}
                                >
                                    <img src={produs.image} alt={produs.name} height="90%" width="100%" />
                                    <Typography
                                        sx={{
                                            m: "auto",
                                            fontWeight: "bold",
                                            textAlign: "center",
                                            pt: 1
                                        }}
                                        color="custom2.main"
                                    >
                                        { produs.name } ({ produseShop.products[produs.name].length })
                                    </Typography>
                                </Box>
                            </Grid>
                        )
                    }
                </Grid>
                <Box
                    sx={{
                        display: "flex",
                        pt: 5
                    }}
                >
                    <Typography
                        sx={{
                            fontFamily: "titleFont",
                            m: "auto",
                            fontWeight: "bold",
                            fontSize: {xs: "2.5rem", md: "4rem"},
                            textAlign: "center"
                        }}
                        color="custom2.main"
                    >
                        ~~Toate Produsele~~
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column"
                    }}
                >
                    <Grid container xs={10} sx={{m: "auto"}}>
                        <Grid item lg={2} />
                        <Grid item xs={12} lg={2}>
                            <Box
                                sx={{
                                    display: "flex",
                                }}
                            >
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        pt: 3,
                                        m: "auto"
                                    }}
                                    onClick={handleClickFilter}
                                >
                                    <Button
                                        color="custom2"
                                        sx={{
                                            fontSize: "1.25rem",
                                            textTransform: "none"
                                        }}
                                        startIcon={<Tune color="custom2" />}
                                    >
                                        Filtre: { filters.length }
                                    </Button>
                                </Box>
                                <Menu 
                                    sx={{
                                        m: "auto",
                                        my: 2
                                    }}
                                    label="Sortare"
                                    open={filterMenuOpen}
                                    onClose={handleClose}
                                    anchorEl={anchorElFilter}
                                >
                                    <MenuItem onClick={(e) => { e.preventDefault(); toggleFilter("post"); }}>
                                        <FormControlLabel 
                                            control={
                                                <Checkbox 
                                                    checked={checkedFilters[0]} 
                                                />
                                            } 
                                            label={"De post (" + getCountForFilter("post") + ")" }
                                        />
                                    </MenuItem>
                                    <MenuItem onClick={(e) => { e.preventDefault(); toggleFilter("new"); }}>
                                        <FormControlLabel 
                                            control={
                                                <Checkbox 
                                                    checked={checkedFilters[1]} 
                                                />
                                            } 
                                            label={"Noutati (" + getCountForFilter("new") + ")" }
                                        />                                    
                                    </MenuItem>
                                </Menu>
                            </Box>
                        </Grid>
                        <Grid item xs={12} lg={1} />
                        <Grid item xs={12} lg={2}>
                            <TextField 
                                sx={{
                                    m: "auto",
                                    my: 2
                                }}
                                focused
                                label="Cautare Produse"
                                value={searchBy}
                                onChange={(e) => { setSearchBy(e.target.value) }}
                                color="custom2"
                                fullWidth
                            />
                        </Grid>
                        <Grid item lg={1} />
                        <Grid item xs={12} lg={2}>
                            <Box
                                sx={{
                                    display: "flex",
                                }}
                            >
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        pt: 3,
                                        m: "auto"
                                    }}
                                    onClick={handleClickOrder}
                                >
                                    <Button
                                        color="custom2"
                                        sx={{
                                            fontSize: "1.25rem",
                                            textTransform: "none"
                                        }}
                                        startIcon={<Sort color="custom2" />}
                                    >
                                        Sortare: { orderBy }
                                    </Button>
                                </Box>
                                <Menu 
                                    sx={{
                                        m: "auto",
                                        my: 2
                                    }}
                                    label="Sortare"
                                    open={orderByMenuOpen}
                                    onClose={handleClose}
                                    anchorEl={anchorElOrder}
                                    value={orderBy}
                                    onChange={(e) => { setOrderBy(e.target.value) }}
                                >
                                    <MenuItem onClick={() => { setOrderBy("Implicit"); handleClose(); }}>
                                        Implicit
                                    </MenuItem>
                                    <MenuItem onClick={() => { setOrderBy("Alfabetic"); handleClose(); }}>
                                        Alfabetic
                                    </MenuItem>
                                    <MenuItem onClick={() => { setOrderBy("Scumpe"); handleClose(); }}>
                                        Scumpe
                                    </MenuItem>
                                    <MenuItem onClick={() => { setOrderBy("Ieftine"); handleClose(); }}>
                                        Ieftine
                                    </MenuItem>
                                </Menu>
                            </Box>
                        </Grid>
                    </Grid>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            m: "auto",
                            gap: "1rem",
                            pt: 1
                        }}
                    >
                        <ArrowBackIos 
                            color="custom2" 
                            sx={{
                                cursor: "pointer",
                                fontSize: "1rem",
                                mt: ".5rem",
                                visibility: currentPage > 1 ? "visible" : "hidden"
                            }}
                            onClick={() => {
                                if(currentPage > 1) {
                                    setCurrentPage(currentPage - 1)
                                }
                            }}
                        />
                        {
                            pages.map((page) =>
                                <Typography
                                    sx={{
                                        fontWeight: "bold",
                                        cursor: "pointer",
                                    }}
                                    color={currentPage === page + 1 ? "custom.main" : "custom2.main"}
                                    variant="h6"
                                    onClick={() => { setCurrentPage(page + 1); }}
                                    key={"page-" + page}
                                >
                                    { page + 1 }
                                </Typography>
                            )
                        }
                        <ArrowForwardIos
                            color="custom2" 
                            sx={{
                                cursor: "pointer",
                                fontSize: "1rem",
                                mt: ".5rem",
                                visibility: currentPage < pages.length ? "visible" : "hidden"
                            }}
                            onClick={() => {
                                if(currentPage < pages.length) {
                                    setCurrentPage(currentPage + 1)
                                }
                            }}
                        />
                    </Box>
                </Box>
                <Grid container xs={10} lg={8}
                    sx={{
                        m: "auto",
                        pb: 5,
                        pt: 5
                    }}
                >
                    {
                        filteredProducts.length === 0 &&
                        <Box
                            sx={{
                                m: "auto"
                            }}
                        >
                            <Typography
                                sx={{
                                    m: "auto",
                                    fontWeight: "bold",
                                    textAlign: "center",
                                    pt: 1
                                }}
                                color="custom2.main"
                                variant="h6"
                            >
                                Cautarea nu a returnat niciun produs.
                            </Typography>
                        </Box>
                    }
                    {
                       getPageContents(filteredProducts).map((produs) => {
                            if(produs !== undefined) {
                                return(
                                    <Grid item xs={12} sm={6} md={6} lg={4}
                                        key={"mashed-prodct-" + produs.name}
                                    >
                                        <Box
                                            sx={{
                                                display: "flex",
                                                flexDirection: "column",
                                                minHeight: {xs: "40vh", sm: "27vh", md: "37vh", lg: "40vh"},
                                                width: {xs: "70vw", sm: "37vw", md: "30vw", lg: "17vw"},
                                                m: "auto",
                                                my: 4,
                                                p: 2,
                                                borderRadius: "8px",
                                                backdropFilter: "brightness(110%)",
                                                boxShadow: "0px 5px 10px 0px #7f390a"
                                            }}
                                            className="widget"
                                            onClick={() => { navigate("/detalii?category=" + produs.category + "&product=" + produs.index); }}
                                        >
                                            <img src={produs.image} alt={produs.name} height="90%" width="100%" />
                                            <Typography
                                                sx={{
                                                    m: "auto",
                                                    fontWeight: "bold",
                                                    textAlign: "center",
                                                    pt: 1
                                                }}
                                                color="custom2.main"
                                            >
                                                { produs.name }
                                            </Typography>
                                            {
                                                (produs.post === true ||
                                                produs.new === true) &&
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        m: "auto",
                                                        pt: 1
                                                    }}
                                                >
                                                    {
                                                        produs.post === true &&
                                                        <Box
                                                            sx={{
                                                                display: "flex",
                                                                flexDirection: "row",
                                                                px: 1
                                                            }}
                                                        >
                                                            <Check sx={{color: "success.main"}} />
                                                            <Typography
                                                                sx={{
                                                                    fontWeight: "bold",
                                                                    textAlign: "center",
                                                                }}
                                                                color="success.main"
                                                            >
                                                                De post
                                                            </Typography>
                                                        </Box>
                                                    }
                                                    {
                                                        produs.new === true &&
                                                        <Box
                                                            sx={{
                                                                display: "flex",
                                                                flexDirection: "row",
                                                                px: 1
                                                            }}
                                                        >
                                                            <Check sx={{color: "custom.main"}} />
                                                            <Typography
                                                                sx={{
                                                                    fontWeight: "bold",
                                                                    textAlign: "center",
                                                                }}
                                                                color="custom.main"
                                                            >
                                                                NOU!
                                                            </Typography>
                                                        </Box>
                                                    }
                                                </Box>
                                            }
                                            <Typography
                                                sx={{
                                                    m: "auto",
                                                    fontWeight: "bold",
                                                    textAlign: "center",
                                                    pt: 1,
                                                }}
                                                color="#333"
                                            >
                                                { getPriceRange(produs) }.00 lei / { getVariantsRange(produs) }
                                            </Typography>
                                        </Box>
                                    </Grid>
                                )} else return null;
                    })}
                </Grid>
                <Box
                    sx={{
                        display: "flex",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            m: "auto",
                            gap: "1rem",
                            pb: 5,
                        }}
                    >
                        <ArrowBackIos 
                            color="custom2" 
                            sx={{
                                cursor: "pointer",
                                fontSize: "1rem",
                                mt: ".5rem",
                                visibility: currentPage > 1 ? "visible" : "hidden"
                            }}
                            onClick={() => {
                                if(currentPage > 1) {
                                    setCurrentPage(currentPage - 1)
                                }
                            }}
                        />
                        {
                            pages.map((page) =>
                                <Typography
                                    sx={{
                                        fontWeight: "bold",
                                        cursor: "pointer"
                                    }}
                                    color={currentPage === page + 1 ? "custom.main" : "custom2.main"}
                                    variant="h6"
                                    onClick={() => { setCurrentPage(page + 1); }}
                                    key={"page-top-" + page}
                                >
                                    { page + 1 }
                                </Typography>
                            )
                        }
                        <ArrowForwardIos
                            color="custom2" 
                            sx={{
                                cursor: "pointer",
                                fontSize: "1rem",
                                mt: ".5rem",
                                visibility: currentPage < pages.length ? "visible" : "hidden"
                            }}
                            onClick={() => {
                                if(currentPage < pages.length) {
                                    setCurrentPage(currentPage + 1)
                                }
                            }}
                        />
                    </Box>
                </Box>
                </React.Fragment>
            }
            {
                selectedCategory !== "" &&
                <React.Fragment>
                    <Box
                        sx={{
                            display: "flex",
                            pt: 5
                        }}
                        className="widget"
                        onClick={() => { changeCategory(""); }}
                    >
                        
                        <Typography
                            sx={{
                                fontFamily: "titleFont",
                                m: "auto",
                                fontWeight: "bold",
                                fontSize: {xs: "2.5rem", md: "4rem"}
                            }}
                            color="custom2.main"
                        >
                            Inapoi la toate produsele
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column"
                        }}
                    >
                        <Grid container xs={10} sx={{m: "auto"}}>
                            <Grid item lg={2} />
                            <Grid item xs={12} lg={2}>
                                <Box
                                    sx={{
                                        display: "flex",
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "row",
                                            pt: 3,
                                            m: "auto"
                                        }}
                                        onClick={handleClickFilter}
                                    >
                                        <Button
                                            color="custom2"
                                            sx={{
                                                fontSize: "1.25rem",
                                                textTransform: "none"
                                            }}
                                            startIcon={<Tune color="custom2" />}
                                        >
                                            Filtre: { filters.length }
                                        </Button>
                                    </Box>
                                    <Menu 
                                        sx={{
                                            m: "auto",
                                            my: 2
                                        }}
                                        label="Sortare"
                                        open={filterMenuOpen}
                                        onClose={handleClose}
                                        anchorEl={anchorElFilter}
                                    >
                                        <MenuItem onClick={(e) => { e.preventDefault(); toggleFilter("post"); }}>
                                            <FormControlLabel 
                                                control={
                                                    <Checkbox 
                                                        checked={checkedFilters[0]} 
                                                    />
                                                } 
                                                label={"De post (" + getCountForFilter("post") + ")" }
                                            />
                                        </MenuItem>
                                        <MenuItem onClick={(e) => { e.preventDefault(); toggleFilter("new"); }}>
                                            <FormControlLabel 
                                                control={
                                                    <Checkbox 
                                                        checked={checkedFilters[1]} 
                                                    />
                                                } 
                                                label={"Noutati (" + getCountForFilter("new") + ")" }
                                            />                                    
                                        </MenuItem>
                                    </Menu>
                                </Box>
                            </Grid>
                            <Grid item xs={12} lg={1} />
                            <Grid item xs={12} lg={2}>
                                <TextField 
                                    sx={{
                                        m: "auto",
                                        my: 2
                                    }}
                                    focused
                                    label="Cautare Produse"
                                    value={searchBy}
                                    onChange={(e) => { setSearchBy(e.target.value) }}
                                    color="custom2"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item lg={1} />
                            <Grid item xs={12} lg={2}>
                                <Box
                                    sx={{
                                        display: "flex",
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "row",
                                            pt: 3,
                                            m: "auto"
                                        }}
                                        onClick={handleClickOrder}
                                    >
                                        <Button
                                            color="custom2"
                                            sx={{
                                                fontSize: "1.25rem",
                                                textTransform: "none"
                                            }}
                                            startIcon={<Sort color="custom2" />}
                                        >
                                            Sortare: { orderBy }
                                        </Button>
                                    </Box>
                                    <Menu 
                                        sx={{
                                            m: "auto",
                                            my: 2
                                        }}
                                        label="Sortare"
                                        open={orderByMenuOpen}
                                        onClose={handleClose}
                                        anchorEl={anchorElOrder}
                                        value={orderBy}
                                        onChange={(e) => { setOrderBy(e.target.value) }}
                                    >
                                        <MenuItem onClick={() => { setOrderBy("Implicit"); handleClose(); }}>
                                            Implicit
                                        </MenuItem>
                                        <MenuItem onClick={() => { setOrderBy("Alfabetic"); handleClose(); }}>
                                            Alfabetic
                                        </MenuItem>
                                        <MenuItem onClick={() => { setOrderBy("Scumpe"); handleClose(); }}>
                                            Scumpe
                                        </MenuItem>
                                        <MenuItem onClick={() => { setOrderBy("Ieftine"); handleClose(); }}>
                                            Ieftine
                                        </MenuItem>
                                    </Menu>
                                </Box>
                            </Grid>
                        </Grid>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                m: "auto",
                                gap: "1rem",
                                pt: 1
                            }}
                        >
                            <ArrowBackIos 
                                color="custom2" 
                                sx={{
                                    cursor: "pointer",
                                    fontSize: "1rem",
                                    mt: ".5rem",
                                    visibility: currentPage > 1 ? "visible" : "hidden"
                                }}
                                onClick={() => {
                                    if(currentPage > 1) {
                                        setCurrentPage(currentPage - 1)
                                    }
                                }}
                            />
                            {
                                pages.map((page) =>
                                    <Typography
                                        sx={{
                                            fontWeight: "bold",
                                            cursor: "pointer"
                                        }}
                                        color={currentPage === page + 1 ? "custom.main" : "custom2.main"}
                                        variant="h6"
                                        onClick={() => { setCurrentPage(page + 1); }}
                                        key={"page-bottom-" + page}
                                    >
                                        { page + 1 }
                                    </Typography>
                                )
                            }
                            <ArrowForwardIos
                                color="custom2" 
                                sx={{
                                    cursor: "pointer",
                                    fontSize: "1rem",
                                    mt: ".5rem",
                                    visibility: currentPage < pages.length ? "visible" : "hidden"
                                }}
                                onClick={() => {
                                    if(currentPage < pages.length) {
                                        setCurrentPage(currentPage + 1)
                                    }
                                }}
                            />
                        </Box>
                    </Box>
                    <Grid container xs={10} lg={8}
                        sx={{
                            m: "auto",
                            pb: 5,
                            pt: 5
                        }}
                    >
                        {
                            getPageContents(filteredProducts).map((produs) => {
                                if(produs !== undefined){
                                    return(
                                        <Grid item xs={12} sm={6} md={6} lg={4}
                                            key={"paged-product-" + produs.name}
                                        >
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    minHeight: {xs: "40vh", sm: "27vh", md: "37vh", lg: "40vh"},
                                                    width: {xs: "70vw", sm: "37vw", md: "30vw", lg: "17vw"},
                                                    m: "auto",
                                                    my: 4,
                                                    p: 2,
                                                    borderRadius: "8px",
                                                    backdropFilter: "brightness(110%)",
                                                    boxShadow: "0px 5px 10px 0px #7f390a"
                                                }}
                                                className="widget"
                                                onClick={() => { navigate("/detalii?category=" + produs.category + "&product=" + produs.index); }}
                                            >
                                                <img src={produs.image} alt={produs.name} height="90%" width="100%" />
                                                <Typography
                                                    sx={{
                                                        m: "auto",
                                                        fontWeight: "bold",
                                                        textAlign: "center",
                                                        pt: 1
                                                    }}
                                                    color="custom2.main"
                                                >
                                                    { produs.name }
                                                </Typography>
                                                {
                                                (produs.post === true ||
                                                produs.new === true) &&
                                                    <Box
                                                        sx={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            m: "auto",
                                                            pt: 1
                                                        }}
                                                    >
                                                        {
                                                            produs.post === true &&
                                                            <Box
                                                                sx={{
                                                                    display: "flex",
                                                                    flexDirection: "row",
                                                                    px: 1
                                                                }}
                                                            >
                                                                <Check sx={{color: "success.main"}} />
                                                                <Typography
                                                                    sx={{
                                                                        fontWeight: "bold",
                                                                        textAlign: "center",
                                                                    }}
                                                                    color="success.main"
                                                                >
                                                                    De post
                                                                </Typography>
                                                            </Box>
                                                        }
                                                        {
                                                            produs.new === true &&
                                                            <Box
                                                                sx={{
                                                                    display: "flex",
                                                                    flexDirection: "row",
                                                                    px: 1
                                                                }}
                                                            >
                                                                <Check sx={{color: "custom.main"}} />
                                                                <Typography
                                                                    sx={{
                                                                        fontWeight: "bold",
                                                                        textAlign: "center",
                                                                    }}
                                                                    color="custom.main"
                                                                >
                                                                    NOU!
                                                                </Typography>
                                                            </Box>
                                                        }
                                                    </Box>
                                                }
                                                <Typography
                                                    sx={{
                                                        m: "auto",
                                                        fontWeight: "bold",
                                                        textAlign: "center",
                                                        pt: 1,
                                                    }}
                                                    color="#333"
                                                >
                                                    { getPriceRange(produs) }.00 lei / { getVariantsRange(produs) }
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    )}
                                        else return null;
                                    })}
                    </Grid>
                    <Box
                        sx={{
                            display: "flex"
                        }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                m: "auto",
                                gap: "1rem",
                                pb: 5
                            }}
                        >
                            <ArrowBackIos 
                                color="custom2" 
                                sx={{
                                    cursor: "pointer",
                                    fontSize: "1rem",
                                    mt: ".5rem",
                                    visibility: currentPage > 1 ? "visible" : "hidden"
                                }}
                                onClick={() => {
                                    if(currentPage > 1) {
                                        setCurrentPage(currentPage - 1)
                                    }
                                }}
                            />
                            {
                                pages.map((page) =>
                                    <Typography
                                        sx={{
                                            fontWeight: "bold",
                                            cursor: "pointer"
                                        }}
                                        color={currentPage === page + 1 ? "custom.main" : "custom2.main"}
                                        variant="h6"
                                        onClick={() => { setCurrentPage(page + 1); }}
                                        key={"paged-products-page-" + page}
                                    >
                                        { page + 1 }
                                    </Typography>
                                )
                            }
                            <ArrowForwardIos
                                color="custom2" 
                                sx={{
                                    cursor: "pointer",
                                    fontSize: "1rem",
                                    mt: ".5rem",
                                    visibility: currentPage < pages.length ? "visible" : "hidden"
                                }}
                                onClick={() => {
                                    if(currentPage < pages.length) {
                                        setCurrentPage(currentPage + 1)
                                    }
                                }}
                            />
                        </Box>
                    </Box>
                </React.Fragment>
            }
        </Paper>
        </React.Fragment>
    );
}