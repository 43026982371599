import { ChevronLeft, LocalShippingOutlined, RedeemOutlined, ShoppingBasketOutlined } from "@mui/icons-material";
import { Divider, IconButton, Toolbar, Box, Typography, List, ListItem, ListItemButton } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { StyledDrawer } from "../../StyledDrawer";
import routes from "../../routes.json"

export default function HomePage({open, setOpen, toggleDrawer}) {

    const navigate = useNavigate();

    React.useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return(
        <React.Fragment>
            <StyledDrawer variant="permanent" open={open} id="drawer-main" >
                <Toolbar
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        px: [1],
                    }}
                >
                <IconButton onClick={toggleDrawer}>
                    <ChevronLeft color="custom" />
                </IconButton>
                </Toolbar>
                <Divider color="#e5d96b" sx={{width: "90%", mx: "auto"}} />
                <List>
                {
                    routes.drawer.map((route) =>
                        <ListItem key={"drawer-route-" + route.name}>
                            <ListItemButton
                                sx={{
                                    color: "custom.main",
                                }}
                                className="drawer-item"
                                onClick={() => { navigate(route.location); setOpen(false); }}
                            >
                                { route.name }
                            </ListItemButton>
                        </ListItem>
                    )
                }
                </List>
            </StyledDrawer>
            <Box
                sx={{
                    backgroundImage: "url(/comenzi_bg.jpg)",
                    backgroundPosition: "50% 50%",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    width: "100vw",
                    minHeight: "80vh",
                    display: "flex",
                    flexDirection: "column"
                }}
            >
                <Box
                    sx={{
                        backdropFilter: "blur(12px) brightness(90%)",
                        m: "auto",
                        mt: 15,
                        mb: {xs: 10, md: 0},
                        maxWidth: {xs: "90%", lg: "50%"},
                        borderRadius: "20px"
                    }}
                >
                    <Typography
                        sx={{
                            color: "primary.contrastText",
                            fontWeight: "bold",
                            textAlign: "center",
                            
                            fontFamily: "oswald",
                            p: 2,
                        }}
                        variant="h5"
                    >
                        Suntem o brutarie care ofera clientilor produse de calitate superioara, cornulete delicioase si alte specialitati. Noi ne concentram pe livrarea unui produs final de inalta calitate si ne asiguram ca avem mereu clienti multumiti, inca din 1994.
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: {xs: "column", md: "row"},
                        m: "auto",
                        gap: {xs: "2rem", md: "10rem", lg: "15rem"},
                        pb: {xs: 5, md: 0}
                    }}
                >
                    <Box
                        className="widget"
                        sx={{
                            display: "flex",
                            flexDirection: "column"
                        }}
                        onClick={() => { navigate("/produse"); }}
                    >
                        <Box
                            sx={{
                                backgroundColor: "#e5d96b",
                                border: "2px solid white",
                                textAlign: "center",
                                display: "flex",
                                borderRadius: "12px",
                                p: 5
                            }}
                        >
                            <ShoppingBasketOutlined
                                sx={{
                                    m: "auto",
                                    fontSize: "4rem",
                                    color: "primary.contrastText"
                                }}
                            />
                        </Box>
                        <Box
                            sx={{
                                display: "flex"
                            }}
                        >
                            <Typography
                                sx={{
                                    m: "auto",
                                    mt: 1,
                                    color: "primary.contrastText",
                                    fontWeight: "bold"
                                }}
                                variant="h5"
                            >
                                Produse
                            </Typography>
                        </Box>
                    </Box>
                    <Box
                        className="widget"
                        sx={{
                            display: "flex",
                            flexDirection: "column"
                        }}
                        onClick={() => { navigate("/livrare"); }}
                    >
                        <Box
                            sx={{
                                backgroundColor: "#e5d96b",
                                border: "2px solid white",
                                textAlign: "center",
                                display: "flex",
                                borderRadius: "12px",
                                p: 5
                            }}
                        >
                            <LocalShippingOutlined
                                sx={{
                                    m: "auto",
                                    fontSize: "4rem",
                                    color: "primary.contrastText"
                                }}
                            />
                        </Box>
                        <Box
                            sx={{
                                display: "flex"
                            }}
                        >
                            <Typography
                                sx={{
                                    m: "auto",
                                    mt: 1,
                                    color: "primary.contrastText",
                                    fontWeight: "bold"
                                }}
                                variant="h5"
                            >
                                Livrare
                            </Typography>
                        </Box>
                    </Box>
                    <Box
                        className="widget"
                        sx={{
                            display: "flex",
                            flexDirection: "column"
                        }}
                        onClick={() => { navigate("/comenzi"); }}
                    >
                        <Box
                            sx={{
                                backgroundColor: "#e5d96b",
                                border: "2px solid white",
                                textAlign: "center",
                                display: "flex",
                                borderRadius: "12px",
                                p: 5
                            }}
                        >
                            <RedeemOutlined
                                sx={{
                                    m: "auto",
                                    fontSize: "4rem",
                                    color: "primary.contrastText"
                                }}
                            />
                        </Box>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column"
                            }}
                        >
                            <Typography
                                sx={{
                                    m: "auto",
                                    mt: 1,
                                    color: "primary.contrastText",
                                    fontWeight: "bold"
                                }}
                                variant="h5"
                            >
                                Comenzi
                            </Typography>
                            <Typography
                                sx={{
                                    m: "auto",
                                    mt: 1,
                                    color: "primary.contrastText",
                                    fontWeight: "bold"
                                }}
                                variant="h5"
                            >
                                Personalizate
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </React.Fragment>
    )
}