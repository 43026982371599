import { ShoppingCart } from "@mui/icons-material";
import { Badge } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useLocation } from "react-router-dom";

export default function ShoppingCartBubble({ shoppingCart, setShoppingCartOpen }) {

    const [shoppingCartQuantity, setShoppingCartQuantity] = React.useState(0);
    const location = useLocation();

    function getShoppingCartQuantity() {
        let quantity = 0;
    
        shoppingCart.forEach((item) => {
          quantity += Number(item.quantity);
        })
    
        return quantity;
      }

    React.useEffect(() => {
        setShoppingCartQuantity(getShoppingCartQuantity());
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shoppingCart])

    return(
        <Box
            sx={{
                position: "fixed",
                bottom: "2rem",
                right: "1rem",
                zIndex: 2,
                borderRadius: "50%",
                border: "2px solid #e5d96b",
                backgroundColor: "#7f390a",
                px: 2,
                py: 2,
                display: location.pathname === "/finalizare" 
                || location.pathname === "/comenzi" 
                || location.pathname === "/comanda-trimisa" ? "none" : "block"
            }}
            id="cart-bubble"
            className="widget"
            onClick={() => { setShoppingCartOpen(true); }}
        >
            <Badge 
                badgeContent={shoppingCartQuantity}
                color="custom"
                sx={{
                    color: "primary.contrastText",
                    '& .MuiBadge-badge': {
                        top: -10,
                        right: -5,
                        padding: '0 4px',
                      }
                }}
            >
                <ShoppingCart fontSize="large" />
            </Badge>
        </Box>
    );
}