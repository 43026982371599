import { Status, Wrapper } from "@googlemaps/react-wrapper";
import { ChevronLeft } from "@mui/icons-material";
import { Divider, IconButton, Toolbar, Box, Typography, Paper, List, ListItem, ListItemButton } from "@mui/material";
import React from "react";
import { StyledDrawer } from "../../StyledDrawer";
import { Map } from "./Map"
import routes from "../../routes.json"
import { useNavigate } from "react-router-dom";

export default function DespreNoiPage({open, setOpen, toggleDrawer}) {

    const center = { lat: 45.411639, lng: 23.373527 };
    const zoom = 15;
    const navigate = useNavigate();

    React.useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const render = (status) => {
        if (status === Status.LOADING) return <h3>{status} ..</h3>;
        if (status === Status.FAILURE) return <h3>{status} ...</h3>;
        return null;
    };

    return(
        <React.Fragment>
            <StyledDrawer variant="permanent" open={open} id="drawer-main" >
                <Toolbar
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        px: [1],
                    }}
                >
                <IconButton onClick={toggleDrawer}>
                    <ChevronLeft color="custom" />
                </IconButton>
                </Toolbar>
                <Divider color="#e5d96b" sx={{width: "90%", mx: "auto"}} />
                <List>
                {
                    routes.drawer.map((route) =>
                        <ListItem key={"drawer-route-" + route.name}>
                            <ListItemButton
                                sx={{
                                    color: "custom.main",
                                }}
                                className="drawer-item"
                                onClick={() => { navigate(route.location); setOpen(false); }}
                            >
                                { route.name }
                            </ListItemButton>
                        </ListItem>
                    )
                }
                </List>
            </StyledDrawer>
            <Paper
                sx={{
                    width: "98vw",
                    m: "auto",
                    p: 2,
                    textAlign: "center",
                    pb: 5,
                    backgroundColor: "#edebeb"
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        pt: 5
                    }}
                >
                    <Typography
                        sx={{
                            fontFamily: "titleFont",
                            m: "auto",
                            fontWeight: "bold",
                            fontSize: {xs: "2.5rem", md: "4rem"}
                        }}
                        color="custom2.main"
                    >
                        ~~Despre Noi~~
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        py: 5
                    }}
                >
                    <Typography
                        sx={{
                            m: "auto",
                        }}
                    >
                        Suntem o firma de panificatie ce produce o larga varietate de produse cum ar fi. Sediul nostru se afla pe Strada Carpati Nr.6, Petrosani.
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        px: 5,
                        my: 5,
                        height: {xs: "50vh", md: "40vh"},
                        width: {xs: "90vw", md: "60vw"},
                        m: "auto"
                    }}
                >
                    <Wrapper apiKey={"AIzaSyBxh5TDUMxCg6uz72U16RvaoPnG83PEQT4"} render={render} >
                        <Map center={center} zoom={zoom} />
                    </Wrapper>
                </Box>
            </Paper>
        </React.Fragment>
    )
}