import { Drawer, styled } from "@mui/material";

const drawerWidth = 300;

export const StyledDrawer = styled(Drawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
      '& .MuiDrawer-paper': {
        position: 'fixed',
        top: 0,
        whiteSpace: 'nowrap',
        backgroundColor: "#333",
        color: theme.palette.custom.contrastText,
        boxShadow: "0px 5px 20px 1px " + theme.palette.custom.main,
        width: drawerWidth,
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
        boxSizing: 'border-box',
        ...(!open && {
          overflowX: 'hidden',
          transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
          width: theme.spacing(0),
        }),
      },
    }),
  );