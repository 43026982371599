import { ChevronLeft } from "@mui/icons-material";
import { Box, Divider, IconButton, List, ListItem, ListItemButton, Paper, Toolbar, Typography } from "@mui/material";
import React from "react";
import { StyledDrawer } from "../../StyledDrawer";
import routes from "../../routes.json"
import { useNavigate } from "react-router-dom";

export default function ConfidentialitatePage({open, setOpen, toggleDrawer}) {

    const navigate = useNavigate();

    React.useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return(
        <React.Fragment>
            <StyledDrawer variant="permanent" open={open} id="drawer-main" >
                <Toolbar
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        px: [1],
                    }}
                >
                <IconButton onClick={toggleDrawer}>
                    <ChevronLeft color="custom" />
                </IconButton>
                </Toolbar>
                <Divider color="#e5d96b" sx={{width: "90%", mx: "auto"}} />
                <List>
                {
                    routes.drawer.map((route) =>
                        <ListItem key={"drawer-route-" + route.name}>
                            <ListItemButton
                                sx={{
                                    color: "custom.main",
                                }}
                                className="drawer-item"
                                onClick={() => { navigate(route.location); setOpen(false); }}
                            >
                                { route.name }
                            </ListItemButton>
                        </ListItem>
                    )
                }
                </List>
            </StyledDrawer>
            <Paper
                sx={{
                    width: "98vw",
                    m: "auto",
                    px: {xs: 1, sm: 10, md: 15, lg: 30},
                    backgroundColor: "#edebeb"
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        pt: 5
                    }}
                >
                    <Typography
                        sx={{
                            fontFamily: "titleFont",
                            m: "auto",
                            fontWeight: "bold",
                            textAlign: "center",
                            fontSize: {xs: "2.5rem", md: "4rem"}
                        }}
                        color="custom2.main"
                    >
                        ~~Politica De Confidentialitate~~
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        px: 5,
                        py: 5,
                    }}
                >
                    <Typography
                        sx={{
                            m: "auto",
                            textAlign: "center",
                            fontWeight: "bold"
                        }}
                        variant="h5"
                    >
                        Cine suntem?
                    </Typography>
                    <Typography
                        sx={{
                            m: "auto",
                            textAlign: "center",
                        }}
                        variant="h6"
                    >
                        Site-ul nostru este&nbsp;
                        
                    </Typography>
                    <Typography
                        sx={{
                            m: "auto",
                            textAlign: "center",
                        }}
                        variant="h6"
                    >
                        <a
                            href="https://cornuletedecasa.ro"
                            style={{ textDecoration: 'none', color: "#7f390a" }}
                        >
                            https://www.cornuletedecasa.ro
                        </a>.
                    </Typography>
                    <Typography
                        sx={{
                            m: "auto",
                            textAlign: "center",
                            fontWeight: "bold",
                            mt: 5
                        }}
                        variant="h5"
                    >
                        Ce date personale colectam si de ce?
                    </Typography>
                    <Typography
                        sx={{
                            m: "auto",
                            textAlign: "center",
                            fontWeight: "bold",
                            mt: 3
                        }}
                        variant="h6"
                    >
                        Comentarii
                    </Typography>
                    <Typography
                        sx={{
                            m: "auto",
                            textAlign: "center",
                        }}
                        variant="h6"
                    >
                        -
                    </Typography>
                    <Typography
                        sx={{
                            m: "auto",
                            textAlign: "center",
                            fontWeight: "bold",
                            mt: 3
                        }}
                        variant="h6"
                    >
                        Media
                    </Typography>
                    <Typography
                        sx={{
                            m: "auto",
                            textAlign: "center",
                        }}
                        variant="h6"
                    >
                        -
                    </Typography>
                    <Typography
                        sx={{
                            m: "auto",
                            textAlign: "center",
                            fontWeight: "bold",
                            mt: 3
                        }}
                        variant="h6"
                    >
                        Formulare de contact
                    </Typography>
                    <Typography
                        sx={{
                            m: "auto",
                            textAlign: "center",
                        }}
                        variant="h6"
                    >
                        Datele pe care le introduceti pe formularul de contact pentru a fi contactati de catre noi.
                    </Typography>
                    <Typography
                        sx={{
                            m: "auto",
                            textAlign: "center",
                            fontWeight: "bold",
                            mt: 3
                        }}
                        variant="h6"
                    >
                        Cookies
                    </Typography>
                    <Typography
                        sx={{
                            m: "auto",
                            textAlign: "center",
                        }}
                        variant="h6"
                    >
                        Nu utilizam cookies.
                    </Typography>
                    <Typography
                        sx={{
                            m: "auto",
                            textAlign: "center",
                            fontWeight: "bold",
                            mt: 3
                        }}
                        variant="h6"
                    >
                        Continut din alte site-uri
                    </Typography>
                    <Typography
                        sx={{
                            m: "auto",
                            textAlign: "center",
                        }}
                        variant="h6"
                    >
                        Nu pastram continut din alte site-uri.
                    </Typography>
                    <Typography
                        sx={{
                            m: "auto",
                            textAlign: "center",
                            fontWeight: "bold",
                            mt: 5
                        }}
                        variant="h5"
                    >
                        Cu cine partajam datele?
                    </Typography>
                    <Typography
                        sx={{
                            m: "auto",
                            textAlign: "center",
                        }}
                        variant="h6"
                    >
                        Nu partajam datele dumneavoastra cu nimeni.
                    </Typography>
                    <Typography
                        sx={{
                            m: "auto",
                            textAlign: "center",
                            fontWeight: "bold",
                            mt: 5
                        }}
                        variant="h5"
                    >
                        Cat timp retinem datele?
                    </Typography>
                    <Typography
                        sx={{
                            m: "auto",
                            textAlign: "center",
                        }}
                        variant="h6"
                    >
                        Datele dumneavoastra sunt retinute pana la finalizarea comenzilor.
                    </Typography>
                    <Typography
                        sx={{
                            m: "auto",
                            textAlign: "center",
                            fontWeight: "bold",
                            mt: 5
                        }}
                        variant="h5"
                    >
                        Ce drepturi aveti cu privire la datele dumneavoastra?
                    </Typography>
                    <Typography
                        sx={{
                            m: "auto",
                            textAlign: "center",
                        }}
                        variant="h6"
                    >
                        Puteti cere sa a furnizam datele introduse la comenzi sau la contact.
                    </Typography>
                    <Typography
                        sx={{
                            m: "auto",
                            textAlign: "center",
                            fontWeight: "bold",
                            mt: 5
                        }}
                        variant="h5"
                    >
                        Unde trimitem date?
                    </Typography>
                    <Typography
                        sx={{
                            m: "auto",
                            textAlign: "center",
                        }}
                        variant="h6"
                    >
                        Nu trimitem datele dumneavoastra niciunde.
                    </Typography>
                    <Typography
                        sx={{
                            m: "auto",
                            textAlign: "center",
                            fontWeight: "bold",
                            mt: 5
                        }}
                        variant="h5"
                    >
                        Informatii de contact
                    </Typography>
                    <Typography
                        sx={{
                            m: "auto",
                            textAlign: "center",
                        }}
                        variant="h6"
                    >
                        Se regasesc la sectiunea&nbsp;
                        <a
                            href="https://cornuletedecasa.ro/contact"
                            style={{ textDecoration: 'none', color: "#7f390a" }}
                        >
                            contact
                        </a>
                        .
                    </Typography>
                    <Typography
                        sx={{
                            m: "auto",
                            textAlign: "center",
                            fontWeight: "bold",
                            mt: 5
                        }}
                        variant="h5"
                    >
                        Cum va protejam datele?
                    </Typography>
                    <Typography
                        sx={{
                            m: "auto",
                            textAlign: "center",
                        }}
                        variant="h6"
                    >
                        Nu retinem nici o data cu caracter personal, mai putin cele de la comenzi. Nu divulgam aceste date nimanui si nici nu le folosim niciodata in scop de marketing sau pentru a va trimite informatii nesolicitate.
                    </Typography>
                </Box>
            </Paper>
        </React.Fragment>
    )
}