import { ChevronLeft } from "@mui/icons-material";
import { Box, Divider, IconButton, List, ListItem, ListItemButton, Paper, Toolbar, Typography } from "@mui/material";
import React from "react";
import { StyledDrawer } from "../../StyledDrawer";
import routes from "../../routes.json"
import { useNavigate } from "react-router-dom";

export default function ComandaTrimisaPage({open, setOpen, toggleDrawer}) {

    const navigate = useNavigate();

    React.useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return(
        <React.Fragment>
            <StyledDrawer variant="permanent" open={open} id="drawer-main" >
                <Toolbar
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        px: [1],
                    }}
                >
                <IconButton onClick={toggleDrawer}>
                    <ChevronLeft color="custom" />
                </IconButton>
                </Toolbar>
                <Divider color="#e5d96b" sx={{width: "90%", mx: "auto"}} />
                <List>
                {
                    routes.drawer.map((route) =>
                    <ListItem key={"drawer-route-" + route.name}>
                        <ListItemButton
                            sx={{
                                color: "custom.main",
                            }}
                            className="drawer-item"
                            onClick={() => { navigate(route.location); setOpen(false); }}
                        >
                            { route.name }
                        </ListItemButton>
                    </ListItem>
                    )
                }
                </List>
            </StyledDrawer>
            <Paper
                sx={{
                    width: "98vw",
                    m: "auto",
                    px: {xs: 1, sm: 10, md: 15, lg: 30},
                    backgroundColor: "#edebeb"
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        pt: 5,
                        px: 2
                    }}
                >
                    <Typography
                        sx={{
                            fontFamily: "titleFont",
                            m: "auto",
                            fontWeight: "bold",
                            fontSize: {xs: "2.5rem", md: "4rem"}
                        }}
                        color="custom2.main"
                    >
                        ~~Va Multumim~~
                    </Typography>
                    <Typography
                        sx={{
                            m: "auto",
                            textAlign: "center",
                            my: 5
                        }}
                        variant="h6"
                    >
                        Comanda dumneavoastra a fost trimisa. Echipa noastra va lua legatura cu dumneavoastra in cel mai scurt timp posibil.
                    </Typography>
                </Box>
            </Paper>
        </React.Fragment>
    )
}