import './App.css';
import React from 'react';
import { ThemeProvider } from '@emotion/react';
import { createTheme, CssBaseline, Snackbar } from '@mui/material';
import { Box } from '@mui/system';
import { StyledMenuButton } from './StyledMenuButton';
import ComenziPage from './pages/comenzi/ComenziPage';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Banner from './Banner';
import HomePage from './pages/comenzi/HomePage';
import { StyledAppBar } from './StyledAppBar';
import StyledFooter from './StyledFooter';
import ProdusePage from './pages/comenzi/ProdusePage';
import DetaliiPage from './pages/comenzi/DetaliiPage';
import ShoppingCartDrawer from './ShoppingCartDrawer';
import LivrarePage from './pages/comenzi/LivrarePage';
import ContactPage from './pages/comenzi/ContactPage';
import DespreNoiPage from './pages/comenzi/DespreNoiPage';
import ConfidentialitatePage from './pages/comenzi/ConfidentialitatePage';
import FinalizarePage from './pages/comenzi/FinalizarePage';
import ComandaTrimisaPage from './pages/comenzi/ComandaTrimisaPage';
import ShoppingCartBubble from './ShoppingCartBubble';
import { OfferDialog } from './OfferDialog';

const theme = createTheme({
  palette: {
    custom: {
      light: "#e5d96b",
      dark: "#e5d96b",
      main: "#e5d96b",
      contrastText: "#ffffff"
    },
    custom2: {
      light: "#7f390a",
      dark: "#7f390a",
      main: "#7f390a",
      contrastText: "$ffffff"
    }
  },
});

function App() {

  const [open, setOpen] = React.useState(false);
  const [shoppingCartOpen, setShoppingCartOpen] = React.useState(false);
  const [shoppingCart, setShoppingCart] = React.useState([]);
  const [showSuccessSnackbar, setShowSuccessSnackbar] = React.useState(false);
  const [offerDialogOpen, setOfferDialogOpen] = React.useState(false);

  function toggleDrawer() {
    setOpen(!open);
  }

  function handleClick(e) {
    let drawerClick = false;
    e.path.forEach((element) => {
      if(element.id !== undefined) {
        if(element.id.includes("drawer") 
          || element.id.includes("cart")
        ) {
          drawerClick = true;
        }
      }
    })

    if(drawerClick === false) {
      setOpen(false);
      setShoppingCartOpen(false);
    }
  }

  function subscribe(event, element, func) {
    if (element.addEventListener) {
        element.addEventListener(event, func, false);
    } else if (element.attachEvent) {
        element.attachEvent("on" + event, func);
    } else {
        element['on' + event] = func;
    }
  }

  React.useEffect(() => {
    subscribe("click", window, handleClick);
    let existingShoppingCart = localStorage.getItem("shopping-cart");
    if(existingShoppingCart != null) {
      setShoppingCart(JSON.parse(existingShoppingCart));
    }

    const dialogOpened = localStorage.getItem("offer-dialog");

    if(dialogOpened == null) {
      setOfferDialogOpen(true);
    }

  }, [])

  React.useEffect(() => {
    if(shoppingCart.length > 0)
      localStorage.setItem("shopping-cart", JSON.stringify(shoppingCart));
  }, [shoppingCart])

  function closeOfferDialog() {
    setOfferDialogOpen(false);
    localStorage.setItem("offer-dialog", false);
  }

  function addToCart(product) {
    const newShoppingCart = [];
    let productExists = false;

    shoppingCart.forEach((existingProduct) => {
      if(existingProduct.product.name === product.product.name &&
          existingProduct.variant.name === product.variant.name
        ) {
          productExists = true;
          existingProduct.quantity = Number(product.quantity) + Number(existingProduct.quantity);
        }

        newShoppingCart.push(existingProduct);
    })

    if(!productExists) {
      newShoppingCart.push(product);
    }

    setShowSuccessSnackbar(true);
    setShoppingCart(newShoppingCart);
  }

  function getShoppingCartPrice() {
    let price = 0;
    shoppingCart.forEach((item) => {
      price += Number(item.quantity) * Number(item.variant.price);
    })

    return price;
  }

  function removeItem(item) {
    const newShoppingCart = [];
    shoppingCart.forEach((existingItem) => {
      if(existingItem.product.name === item.product.name &&
          existingItem.variant.name === item.variant.name &&
          existingItem.quantity === item.quantity
        ) {

        } else {
          newShoppingCart.push(existingItem);
        }
    })

    setShoppingCart(newShoppingCart);
  }

  return (
    <ThemeProvider theme={theme}
    >
      <CssBaseline />
      <StyledMenuButton open={open} toggleDrawer={toggleDrawer} />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#e5d96b",
          minWidth: "100vw",
          minHeight: "100vh",
        }}
      >
        <BrowserRouter>
          <Banner />
          <ShoppingCartDrawer shoppingCartOpen={shoppingCartOpen} setShoppingCartOpen={setShoppingCartOpen}
         shoppingCart={shoppingCart} getShoppingCartPrice={getShoppingCartPrice} removeItem={removeItem} />
          <StyledAppBar shoppingCart={shoppingCart} setShoppingCartOpen={setShoppingCartOpen} />
          <Routes>
            <Route path="/comenzi" element={<ComenziPage open={open} setOpen={setOpen} toggleDrawer={toggleDrawer} />} />
            <Route exact path="/" element={<HomePage open={open} setOpen={setOpen} toggleDrawer={toggleDrawer} />} />
            <Route path="/livrare" element={<LivrarePage open={open} setOpen={setOpen} toggleDrawer={toggleDrawer} />} />
            <Route path="/produse" element={<ProdusePage open={open} setOpen={setOpen} toggleDrawer={toggleDrawer} />} />
            <Route path="/contact" element={<ContactPage open={open} setOpen={setOpen} toggleDrawer={toggleDrawer} />} />
            <Route path="/despre-noi" element={<DespreNoiPage open={open} setOpen={setOpen} toggleDrawer={toggleDrawer} />} />
            <Route path="/finalizare" element={<FinalizarePage open={open} setOpen={setOpen} toggleDrawer={toggleDrawer} shoppingCart={shoppingCart} getShoppingCartTotal={getShoppingCartPrice} setShoppingCart={setShoppingCart} />} />
            <Route path="/confidentialitate" element={<ConfidentialitatePage open={open} setOpen={setOpen} toggleDrawer={toggleDrawer} />} />
            <Route path="/detalii" element={<DetaliiPage open={open} setOpen={setOpen} toggleDrawer={toggleDrawer} addToCart={addToCart} />} />
            <Route path="/comanda-trimisa" element={<ComandaTrimisaPage open={open} setOpen={setOpen} toggleDrawer={toggleDrawer} />} />
          </Routes>
          <ShoppingCartBubble setShoppingCartOpen={setShoppingCartOpen} shoppingCart={shoppingCart} />
          <StyledFooter />
          <OfferDialog open={offerDialogOpen} closeDialog={closeOfferDialog} />
        </BrowserRouter>
      </Box>
      <Snackbar
        open={showSuccessSnackbar}
        onClose={() => { setShowSuccessSnackbar(false); }}
        autoHideDuration={3000}
        message="Produsul a fost adaugat in cos!"
        ContentProps={{
          sx:{
            background: "#2e7d32"
          }
        }}
      />
    </ThemeProvider>
  );
}

export default App;
