import { ChevronLeft } from "@mui/icons-material";
import { Divider, IconButton, List, ListItem, ListItemButton, Toolbar } from "@mui/material";
import React from "react";
import { StyledDrawer } from "../../StyledDrawer";
import routes from "../../routes.json"
import { useNavigate } from "react-router-dom";

export default function ContactPage({open, setOpen, toggleDrawer}) {

    const navigate = useNavigate();

    React.useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return(
        <React.Fragment>
            <StyledDrawer variant="permanent" open={open} id="drawer-main" >
                <Toolbar
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        px: [1],
                    }}
                >
                <IconButton onClick={toggleDrawer}>
                    <ChevronLeft color="custom" />
                </IconButton>
                </Toolbar>
                <Divider color="#e5d96b" sx={{width: "90%", mx: "auto"}} />
                <List>
                {
                    routes.drawer.map((route) =>
                        <ListItem key={"drawer-route-" + route.name}>
                            <ListItemButton
                                sx={{
                                    color: "custom.main",
                                }}
                                className="drawer-item"
                                onClick={() => { navigate(route.location); setOpen(false); }}
                            >
                                { route.name }
                            </ListItemButton>
                        </ListItem>
                    )
                }
                </List>
            </StyledDrawer>

        </React.Fragment>
    )
}