import { Box } from "@mui/material";
import React from "react";

export function Map({ center, zoom }) {
    const ref = React.useRef();
    const google = window.google;
  
    React.useEffect(() => {
      const map = new window.google.maps.Map(ref.current, {
        center,
        zoom,
      });

      new google.maps.Marker({
        position: center,
        map,
        title: "Hello World!",
      });
    });
  
    return <Box
        sx={{
            width: "100%",
            height: "100%"
        }}
        ref={ref} 
        id="map" 
     />;
  }