import { Clear } from "@mui/icons-material";
import { Button, Dialog, Typography } from "@mui/material"
import { Box } from "@mui/system"
import { useNavigate } from "react-router-dom";

export function OfferDialog({ open, closeDialog }) {

    const navigate = useNavigate();

    return(
        <Dialog
            open={open}
            onClose={() => { closeDialog(); }}
        >
            <Box
                sx={{
                    backgroundColor: "#7f390a",
                    p: 1,
                    display: "flex",
                    flexDirection: "column"
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        backgroundColor: "#e5d96b",
                        p: 2,
                        borderRadius: "8px"
                    }}
                >
                    <Clear
                        sx={{
                            color: "custom2.main"
                        }}
                        className="widget"
                        onClick={() => { closeDialog(); }}
                    />
                    <Typography
                        sx={{
                            fontFamily: "titleFont",
                            m: "auto",
                            fontWeight: "bold",
                            fontSize: {xs: "2.5rem", md: "4rem"}
                        }}
                        color="custom2.main"
                    >
                        Buna ziua   
                    </Typography>
                    <Typography
                        sx={{
                            fontFamily: "titleFont",
                            m: "auto",
                            fontWeight: "bold",
                            fontSize: {xs: "2.0rem", md: "3rem"},
                            textAlign: "center"
                        }}
                        color="custom2.main"
                    >
                        Recent, am adaugat un produs nou: cornulete cu nuca.  
                    </Typography>
                    <Button
                        color="custom2"
                        variant="contained"
                        sx={{
                            maxWidth: "15rem",
                            m: "auto",
                            color: "#ffffff",
                            mt: 1
                        }}
                        className="widget"
                        onClick={() => { navigate("/produse"); closeDialog(); }}
                    >
                        Catre Magazin
                    </Button>
                </Box>
            </Box>
        </Dialog>
    );
}