import { Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useNavigate } from "react-router-dom";

export default function Banner() {

    const navigate = useNavigate();

    return(
        <Paper
            sx={{
                backgroundColor: "#e5d96b",
                height: "3rem",
                width: "100%"
            }}
        >
            <Box
                sx={{
                    m: "auto",
                }}
            >
                <Typography
                    sx={{
                        m: "auto",
                        textAlign: "center",
                        color: "primary.contrastText",
                        pt: "0.5rem",
                        fontFamily: "oswald",
                        cursor: "pointer"
                    }}
                    variant="h6"
                    onClick={() => { navigate("/produse"); }}
                >
                    Livrare rapidă pe tot teritoriul țării!
                </Typography>
            </Box>
        </Paper>
    )
}