import { Cancel, Send } from "@mui/icons-material";
import { Button, Dialog, DialogTitle, Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { StyledTextField } from "./StyledTextField";
import emailjs from "@emailjs/browser"
import React from "react";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from "dayjs";
import customParseFormat from 'dayjs/plugin/customParseFormat'

export function Comenzi ({ items, setItems, setOpen }) {

    const [name, setName] = React.useState("");
    const [phone, setPhone] = React.useState("");
    const [details, setDetails] = React.useState("");
    const [date, setDate] = React.useState("");
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [dialogStatus, setDialogStatus] = React.useState("");

    function removeItem(itemToRemove) {
        const newItems = [];
        items.forEach((item) => {
            if(item !== itemToRemove) {
                newItems.push(item);
            }
        })

        setItems(newItems);
    }

    function calculatePrice() {
        let totalPrice = 0;

        items.forEach(item => {
            totalPrice += item.quantity * item.item.price;
        })

        return totalPrice;
    }

    function printItems() {
        let printedItems = "";

        items.forEach(item => {
            printedItems += item.item.name + " " + item.item.quantity + " x " + item.quantity + ", ";
        })

        return printedItems;
    }

    function showErrorDialog() {
        setDialogOpen(true);
        setDialogStatus("bad-form");
    }

    function showSendErrorDialog() {
        setDialogOpen(true);
        setDialogStatus("send-fail");
    }

    function showSuccessDialog() {
        setDialogOpen(true);
        setDialogStatus("success");
    }

    function getDialogMessage() {
        if(dialogStatus === "bad-form") {
            if(!validatePhone()) {
                return "Va rugam introduceti un numar de telefon mobil corect."
            }

            if(!validateName()) {
                return "Va rugam introduceti un nume."
            }

            if(!validateDate()) {
                return "Va rugam sa alegeti o data valida pentru ridicarea comenzii."
            }
        }

        if(dialogStatus === "success") {
            return "Comanda a fost trimisa cu succes!";
        }

        if(dialogStatus === "send-fail") {
            return "Din pacate, a aparut o problema. Va rugam sa incercati din nou.";
        }
    }

    function resetForm() {
        setName("");
        setPhone("");
        setDetails("");
        setDate("");
        setItems([]);
    }

    function validatePhone() {
        if(phone.length === 10) {
            if(phone[0] === "0" && phone[1] === "7") {
                return true;
            }
        }

        return false;
    }

    function validateName() {
        if(name.length > 2) {
            return true;
        }

        return false;
    }

    function validateDate() {
        if(date === "") {
            return false;
        }

        dayjs.extend(customParseFormat)
        const selectedDate = dayjs(date, ["M/DD/YYYY", "MM/DD/YYYY", "M/D/YYYY", "MM/D/YYYY"]);
        const currentDate = dayjs();

        if(selectedDate.year() > currentDate.year()) {
            return true;
        }

        if(selectedDate.year() < currentDate.year()) {
            return false;
        }

        if(selectedDate.month() > currentDate.month()) {
            return true;
        }

        if(selectedDate.month() < currentDate.month()) {
            return false;
        }

        if(selectedDate.month() === currentDate.month() &&
           selectedDate.date() > currentDate.date() + 1) {
            return true;
        }

        return false;
    }

    function canSubmit() {
        return validatePhone() && validateName() && validateDate();
    }

    const sendEmail = (e) => {
        e.preventDefault();
        emailjs.sendForm("service_251tana", "template_poglide", e.target, "ziO2QMbsBBShza9Mb")
            .then(() => {
                resetForm();
                showSuccessDialog();
            }
            , () => {
                showSendErrorDialog();
            })
    }

    return(
        <Paper
            sx={{
                mx: "auto",
                width: "80%",
                backgroundImage: "url(/comenzi_bg.jpg)",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                borderRadius: "8px"
            }}
            elevation={20}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    height: "100%",
                    background: "rgba(0, 0, 0, 0) url(/pattern-overlay.png) repeat scroll 0 0",
                    borderRadius: "8px"
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                        m: "auto",
                        minHeight: "5rem",
                        maxWidth: "80%",
                        px: 0,
                        pt: 5
                    }}
                >
                    {
                        items.length === 0 &&
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column"
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: "1rem",
                                    m: "auto"
                                }}
                                color="custom.main"
                            >
                                Nu ati selectat niciun produs.
                            </Typography>
                            <Button
                                sx={{
                                    m: "auto"
                                }}
                                color="custom"
                                id="comenzi-drawer-toggle"
                                onClick={() => { setOpen(true); }}
                            >
                                Deschideti Meniul
                            </Button>
                        </Box>
                    }
                    {
                        items.length > 0 &&
                        <Typography
                            sx={{
                                fontSize: "1rem",
                                textAlign: "center",
                                width: "100%"
                            }}
                            color="custom.main"
                        >
                            Produse Selectate
                        </Typography>
                    }
                    {
                        items.map((item) => 
                            <Button
                                color="custom"
                                key={"box-" + item.item.name + "-" + item.item.quantity}
                                sx={{
                                    m: "auto"
                                }}
                                endIcon={<Cancel onClick={() => { removeItem(item); }} color="error" />}
                            >  
                                { item.item.name } { item.item.quantity } ({ item.quantity })
                            </Button>
                        )
                    }
                    {
                        items.length > 0 &&
                        <Typography
                            sx={{
                                fontSize: "1.5rem",
                                textAlign: "center",
                                width: "100%",
                                mt: "1rem"
                            }}
                            color="custom.main"
                        >
                            Total: {calculatePrice()}lei
                        </Typography>
                    }
                </Box>
                <form
                    onSubmit={sendEmail}
                >
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            m: "auto",
                            p: 5
                        }}
                    >
                        <StyledTextField 
                            sx={{
                                mb: 3,
                                mt: 6
                            }}
                            variant="outlined"
                            color="custom"
                            label="Nume"
                            name="name"
                            value={name}
                            onChange={(e) => { setName(e.target.value) }}
                            focused
                            inputProps={{ style: { color: "#e5d96b" } }}
                        />
                        <StyledTextField 
                            sx={{
                                my: 3
                            }}
                            variant="outlined"
                            color="custom"
                            label="Telefon"
                            name="phone"
                            value={phone}
                            onChange={(e) => { setPhone(e.target.value) }}
                            focused
                            inputProps={{ style: { color: "#e5d96b" } }}
                        />
                        <StyledTextField 
                            sx={{
                                my: 3
                            }}
                            variant="outlined"
                            color="custom"
                            label="Detalii"
                            name="details"
                            value={details}
                            onChange={(e) => { setDetails(e.target.value) }}
                            focused
                            multiline
                            rows={5}
                            inputProps={{ style: { color: "#e5d96b" } }}
                        />
                        <StyledTextField 
                            sx={{
                                my: 3,
                                display: "none"
                            }}
                            variant="outlined"
                            color="custom"
                            name="items"
                            value={printItems()}
                            focused
                            inputProps={{ style: { color: "#e5d96b" } }}
                        />
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <MobileDatePicker
                                label="Ridicarea Comenzii"
                                inputFormat="DD/MM/YYYY"
                                value={date}
                                onChange={(e) => { setDate((e.month() + 1) + "/" + e.date() + "/" + e.year()) }}
                                renderInput={(params) => <StyledTextField
                                    sx={{
                                        my: 3
                                    }}
                                    variant="outlined"
                                    color="custom"
                                    name="date"
                                    focused
                                    {...params}
                                    inputProps={{ style: { color: "#e5d96b" }, ...params.inputProps }}
                                    error={false}
                                />}
                            />
                        </LocalizationProvider>
                        {
                            canSubmit() &&
                            <Button
                                sx={{
                                    my: 3
                                }}
                                color="custom"
                                variant="contained"
                                startIcon={<Send />}
                                type="submit"
                            >
                                Trimite
                            </Button>
                        }
                        {
                            !canSubmit() &&
                            <Button
                                sx={{
                                    my: 3
                                }}
                                color="custom"
                                variant="contained"
                                startIcon={<Send />}
                                onClick={() => { showErrorDialog(); }}
                            >
                                Trimite
                            </Button>
                        }
                    </Box>
                </form>
            </Box>
            <Dialog
                open={dialogOpen}
                onClose={() => { setDialogOpen(false); }}
            >
                <DialogTitle
                    sx={{
                        p: 5,
                        backgroundColor: "#e5d96b",
                        textAlign: "center"
                    }}
                >
                    { getDialogMessage() }
                </DialogTitle>
            </Dialog>
        </Paper>
    );
}